import { Usuario } from './../../models/Usuario';
import { Component, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { logWarnings } from 'protractor/built/driverProviders';
import { Tipo } from '../../models/Tipo';
import { ItemClass } from '../../clases/items.class';
import { Item } from '../../models/Item';

import Swal from 'sweetalert2';

declare const $: any;


@Component({
  selector: 'app-detalle-item3',
  templateUrl: './detalle-item3.component.html'
})
export class DetalleItem3Component implements OnInit, AfterViewInit {

  title = 'Mapa IPLANAY';
  strTipo: string;
  tipo: Tipo;
  itemN:Item;
  opt: string;
  private sub: any;
  modificacion: string;
  usuario: Usuario;
  validado: string;


  zoom = 15;
  // lat = 21.504163127666864;
  // lng = -104.89461760596936;
  lat = 0;
  lng = 0;

  nlat: number;
  nlng: number;


  constructor(private route: ActivatedRoute, private router: Router, public item: ItemClass) {
    this.tipo = new Tipo();
    this.itemN = new Item();
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(){
    this.sub = this.route.params.subscribe(params => {
      this.item.items = [];
      this.itemN = new Item();
      this.tipo = JSON.parse(localStorage.getItem('tipoActual'));
      this.itemN.idtipo = this.tipo.idtipo;
      this.itemN.tipo = this.tipo.tipo;

      this.strTipo = params['tipo'];
      this.opt = params['opt'];
      this.validado = params['validado'];

      console.log('TIPO: ' + this.tipo);
      console.log('OPT: ' + this.opt);


      // alert(localStorage.getItem('modificarItem'));
      this.modificacion = localStorage.getItem('modificarItem');
      if (this.modificacion === '0') {
        this.item.tipo = this.tipo;

        this.itemN.localizacion.idmunicipio = this.usuario.municipios[0].idmunicipio;
        this.getItems();

        this.getPosition();

      } else {
        this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
        console.log('Item Detalle: ' + this.itemN);
        this.item.items.push(this.itemN);
        this.lat = Number(this.itemN.latitud);
        this.lng = Number(this.itemN.longitud);
        // console.error('Modificando Item: ' + this.itemN);
        this.item.getItem(this.itemN);
        this.itemN = this.item.item;

        // this.muestraModalPropuedades(this.itemN);
      }
     });
  }

  mostrarPunto(evento){

    this.nlat = evento.coords.lat;
    this.nlng = evento.coords.lng;
    //this.openModal();
  }

  mostrarCoor(evento, item:Item){
    console.log(item);

    const latAux = this.item.item.latitud;
    const lonAux = this.item.item.longitud;

    Swal.fire({
      title: '¿Modificar Coordenadas?',
      text: 'Se modificaran las coordenadas!!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, modificar!'
    }).then((result) => {
      if (result.isConfirmed) {





        // Swal.fire(
        //   'Modificar!',
        //   'Se han modificado las coordenadas.',
        //   'success'
        // );
        //console.log(evento.coords.lat);
        this.item.item.latitud = evento.coords.lat;
        this.item.item.longitud = evento.coords.lng;

        this.item.modificarCoordenadasItem(this.item.item.iditem, evento.coords.lat, evento.coords.lng);
      }else{
        //this.item.item.latitud = latAux;
        //this.item.item.longitud = lonAux;
        item.latitud = latAux;
        item.longitud = lonAux;
      }
    });
  }

  nuevoItem( evento ){
    this.itemN.latitud = evento.coords.lat;
    this.itemN.longitud = evento.coords.lng;
    $('#modalNuevo').modal('show');
  }


  guardarItem(event: Item){
    this.itemN = event;
    console.log(event);
    $('#modalNuevo').modal('hide');
    $('#modalPropiedades').modal('show');
  }

  muestraModalPropuedades(item: Item){
    this.itemN = item;
    $('#modalPropiedades').modal('show');
  }

  getPosition(): Promise<any>{
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        this.lat = resp.coords.latitude;
        this.lng = resp.coords.longitude;
        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          this.lat = 21.504163127666864;
          this.lng = -104.89461760596936;
          reject(err);
        });
    });

  }


  regresar(): void{
    localStorage.setItem('tipoActual', JSON.stringify(this.tipo));
    this.router.navigate(['/listado', this.tipo.tipo]);
  }


  validarRegistro(){
    this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
    Swal.fire({
      title: '¿Validar Registro? - ' + this.itemN.iditem,
      text: 'Una vez validado este registro, no podra ser modificado, ¿esta seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.item.validarRegistro(this.itemN.iditem, this.tipo.supervision)){
          this.validado = '1';
        }
      }
    });
  }

  habilitarRegistro(){
    this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
    console.log(this.itemN);
    Swal.fire({
      title: 'Habilitar Registro?'  + this.itemN.iditem,
      text: 'Confirmar Habilitar Registro',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.item.habilitarRegistro(this.itemN, this.tipo.supervision)){
          this.validado = '0';
        }
      }
    });
  }

  getItems(){
    this.item.getItems();
  }

}
