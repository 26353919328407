import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContenedorComponent } from './pages/contenedor/contenedor.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { Error404Component } from './pages/error404/error404.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { ListadoItemsComponent } from './components/listado-items/listado-items.component';
import { DetalleItemComponent } from './pages/detalle-item/detalle-item.component';
import { ReportePdfComponent } from './pages/reporte-pdf/reporte-pdf.component';
import { DetalleItem2Component } from './pages/detalle-item2/detalle-item2.component';
import { DetalleItem3Component } from './pages/detalle-item3/detalle-item3.component';
import { DetalleItemListaComponent } from './pages/detalle-item-lista/detalle-item-lista.component';
import { DetalleItemAcordionComponent } from './pages/detalle-item-acordion/detalle-item-acordion.component';
import { DesofizializarComponent } from './pages/desofizializar/desofizializar.component';
import { SolicitudesDesoficializarComponent } from './components/solicitudes-desoficializar/solicitudes-desoficializar.component';

const routes: Routes = [

  {
    path: '',
    component: ContenedorComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      // { path: 'mapa/:tipo', component: MapaComponent },
      { path: 'listado/:tipo', component: ListadoItemsComponent },
      { path: 'mapa/:tipo', component: MapaComponent },
      { path: 'perfil', component: PerfilComponent },
      { path: 'detalle/:tipo/:opt/:validado', component: DetalleItemAcordionComponent },
      { path: 'editar/:tipo/:opt/:validado', component: DetalleItemAcordionComponent },
      { path: 'perfil', component: PerfilComponent },
      { path: 'desoficializar', component: DesofizializarComponent },
      { path: 'solicitudes', component: SolicitudesDesoficializarComponent }
    ]
  },
  { path: 'pdf', component: ReportePdfComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
