import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { Item } from '../../models/Item';
import { ItemClass } from '../../clases/items.class';
import { Tipo } from '../../models/Tipo';
import { ItemService } from '../../services/item.service';
import { Respuesta } from '../../models/Respuesta';



import Swal from 'sweetalert2';
import { Usuario } from '../../models/Usuario';



@Component({
  selector: 'app-nuevo-item',
  templateUrl: './nuevo-item.component.html'
})
export class NuevoItemComponent implements OnInit, OnChanges {

  @Input() item: Item;
  @Input() opt: string;
  @Output() itemGuardado = new EventEmitter<Item>();

  tipo: Tipo;
  resp: Respuesta;
  modificacion: string;
  usuario: Usuario;


  constructor(public objItem: ItemClass, private _itemService: ItemService) {
    this.item = new Item();
    this.tipo = new Tipo();
    this.resp = new Respuesta();
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));

  }

  ngOnInit(): void {
    this.tipo = JSON.parse(localStorage.getItem('tipoActual'));
    this.item.idtipo = this.tipo.idtipo;
    let mun = (Number.parseInt(this.usuario.municipios[0].idmunicipio)<10) ? 0 + this.usuario.municipios[0].idmunicipio : this.usuario.municipios[0].idmunicipio;
    this.item.nombre = mun + this.tipo.siglas;
    // console.log("TIPO ACTUAL: " + this.tipo.idtipo);
  }

  ngOnChanges(): void {
    this.opt = JSON.parse(localStorage.getItem('opt'));
    // alert(this.opt);
  }


  devolverItem() {
    this.itemGuardado.emit(this.item);
  }

  guardar(){
    this.modificacion = localStorage.getItem('modificarItem');
    if ( this.modificacion === '0' ) {
      this.guardarItem();
    }else{
      this.modificarItem();
    }
  }

  guardarItem(){
    // this.objItem.item = this.item;
    // console.log(this.item.idtipo);
    // this.objItem.guardarItem();
    // this.item = this.objItem.item;
    // if(!this.objItem.resp.error){
    //   this.devolverItem();
    // }


    this._itemService.item = this.item;
    this._itemService.guardarItem().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        }else{
          this.item = this.resp.data[0];
          console.log(this.item);
          this.devolverItem();
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }

  modificarItem(){

    this._itemService.item = this.item;
    this._itemService.modificarItem().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        }else{


          Swal.fire(
            'Datos Generales',
            this.resp.mensaje,
            'success'
          );
          this.item = this.resp.data[0];
          console.log(this.item);
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }

}

