import { Usuario } from './../../models/Usuario';
import { Component, EventEmitter, OnInit, AfterViewInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { logWarnings } from 'protractor/built/driverProviders';
import { Tipo } from '../../models/Tipo';
import { ItemClass } from '../../clases/items.class';
import { Item } from '../../models/Item';

import Swal from 'sweetalert2';
import { ItemService } from '../../services/item.service';
import { Respuesta } from '../../models/Respuesta';

declare const $: any;

@Component({
  selector: 'app-detalle-item-acordion',
  templateUrl: './detalle-item-acordion.component.html'
})
export class DetalleItemAcordionComponent implements OnInit, AfterViewInit, OnChanges {

  title = 'Mapa IPLANAY';
  strTipo: string;
  tipo: Tipo;
  itemN: Item;
  opt: string;
  private sub: any;
  modificacion: string;
  usuario: Usuario;
  validado: string;
  estatus_desc: string;


  resp: Respuesta;

  //img = 'https://asistentedesalud.nayarit.gob.mx/IPLANAY_INMUEBLES/restful/index.php/Items/getImagenItem/';
  img = 'https://inmuebles.iplanay.gob.mx/restful/index.php/Items/getImagenItem/';
  pdf = 'https://inmuebles.iplanay.gob.mx/restful/pdf/descarga.php?iditem=';


  zoom = 15;
  // lat = 21.504163127666864;
  // lng = -104.89461760596936;
  lat = 0;
  lng = 0;

  nlat: number;
  nlng: number;


  // tslint:disable-next-line:variable-name
  constructor(private route: ActivatedRoute, private router: Router, public item: ItemClass, public _itemService: ItemService) {
    this.tipo = new Tipo();
    this.itemN = new Item();
    this.usuario = new Usuario();
    this.resp = new Respuesta();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    // this.getItem(this.itemN);
  }

  ngAfterViewInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.item.items = [];
      this.itemN = new Item();
      this.tipo = JSON.parse(localStorage.getItem('tipoActual'));
      this.itemN.idtipo = this.tipo.idtipo;
      this.itemN.tipo = this.tipo.tipo;

      this.strTipo = params.tipo;
      this.opt = params.opt;
      this.validado = params.validado;

      // console.log('TIPO: ' + this.tipo);
      // console.log('OPT: ' + this.opt);


      // alert(localStorage.getItem('modificarItem'));
      this.modificacion = localStorage.getItem('modificarItem');
      if (this.modificacion === '0') {
        this.item.tipo = this.tipo;

        this.itemN.localizacion.idmunicipio = this.usuario.municipios[0].idmunicipio;
        this.getItems();

        this.getPosition();

      } else {
        console.log('Dentro de consulta de ITEM');
        this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
        console.log(this.itemN);
        this.item.items.push(this.itemN);
        console.log(this.item.items);
        this.lat = Number(this.itemN.latitud);
        this.lng = Number(this.itemN.longitud);
        // console.error('Modificando Item: ' + this.itemN);
        // this.item.getItem(this.itemN);
        this.getItem();
        // this.itemN = this.item.item;
        console.log('ITEM comsultado: ' + this.itemN);

        // this.muestraModalPropuedades(this.itemN);
      }
      // console.log(this.item.items[0].imagenes[0].idimagen);
    });
  }

  public getItem(): void {
    // alert('getItem()');
    this._itemService.getItem(this.itemN.iditem).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          this.itemN = this.resp.data[0];
          console.log(this.resp.data[0]);
          // item = this.resp.data[0];
          // alert(this.item.imagenes.length);
          if (this.itemN.imagenes[0].idimagen !== null) {
            // tslint:disable-next-line:max-line-length
            this.img = 'https://asistentedesalud.nayarit.gob.mx/IPLANAY_INMUEBLES/restful/index.php/Items/getImagenItem/' + this.itemN.imagenes[0].idimagen;
          } else {
            this.img = 'assets/img/SIN-IMAGEN.jpeg';
          }
          // console.log(this.item);
        }

      },
      err => {
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }

  mostrarPunto(evento): void {

    this.nlat = evento.coords.lat;
    this.nlng = evento.coords.lng;
    // this.openModal();
  }

  mostrarCoor(evento, item: Item): void {
    // console.log(item);

    const latAux = this.item.item.latitud;
    const lonAux = this.item.item.longitud;

    Swal.fire({
      title: '¿Modificar Coordenadas?',
      text: 'Se modificaran las coordenadas!!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, modificar!'
    }).then((result) => {
      if (result.isConfirmed) {





        // Swal.fire(
        //   'Modificar!',
        //   'Se han modificado las coordenadas.',
        //   'success'
        // );
        // console.log(evento.coords.lat);
        this.item.item.latitud = evento.coords.lat;
        this.item.item.longitud = evento.coords.lng;

        this.item.modificarCoordenadasItem(this.item.items[0].iditem, evento.coords.lat, evento.coords.lng);
      } else {
        // this.item.item.latitud = latAux;
        // this.item.item.longitud = lonAux;
        item.latitud = latAux;
        item.longitud = lonAux;
      }
    });
  }

  nuevoItem(evento): void {
    this.itemN.latitud = evento.coords.lat;
    this.itemN.longitud = evento.coords.lng;
    $('#modalNuevo').modal('show');
  }


  guardarItem(event: Item): void {
    this.itemN = event;
    // console.log(event);
    $('#modalNuevo').modal('hide');
    $('#modalPropiedades').modal('show');
  }

  muestraModalPropuedades(item: Item): void {
    this.itemN = item;
    $('#modalPropiedades').modal('show');
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        this.lat = resp.coords.latitude;
        this.lng = resp.coords.longitude;
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          this.lat = 21.504163127666864;
          this.lng = -104.89461760596936;
          reject(err);
        });
    });

  }


  regresar(): void {
    localStorage.setItem('tipoActual', JSON.stringify(this.tipo));
    this.router.navigate(['/listado', this.tipo.tipo]);
  }


  validarRegistro(): void {
    this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
    Swal.fire({
      title: '¿Validar Registro? - ' + this.itemN.iditem,
      text: 'Una vez validado este registro, no podra ser modificado, ¿esta seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.item.validarRegistro(this.itemN.iditem, this.tipo.supervision)) {
          this.validado = '1';
          if (this.tipo.supervision === '1') {
            this.itemN.idestatus = '3';
            this.itemN.estatus = 'Validación Externa';
          } else {
            this.itemN.idestatus = '4';
            this.itemN.estatus = 'Oficializado';
          }
        }
      }
    });
  }

  habilitarRegistro(): void {
    this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
    // console.log(this.itemN);
    Swal.fire({
      title: 'Rechazar Registro?',
      text: 'Confirmar Rechazar Registro',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Observaciones',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Guardar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,

          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(result);
            this.itemN.observaciones = result.value;
            if (this.item.habilitarRegistro(this.itemN, this.tipo.supervision)) {
              this.validado = '0';
              if (this.tipo.supervision === '1') {
                this.itemN.idestatus = '1';
                this.itemN.estatus = 'En Captura';
              } else {
                this.itemN.idestatus = '2';
                this.itemN.estatus = 'Validacion Interna';
              }
            }
          }
        })

      }
    });
  }


  cerrarCaptura(): void {
    this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
    Swal.fire({
      title: '¿Cerrar Captura? - ' + this.itemN.iditem,
      text: 'Una vez cerrada la captura de este registro, no podra ser modificado, ¿esta seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.item.cerrarCaptura(this.itemN.iditem, this.tipo.supervision)) {
          // this.validado = '1';
          this.itemN.idestatus = '2';
          this.itemN.estatus = 'Validación Interna';
        }
      }
    });
  }

  getItems(): void {
    this.item.getItems();
  }


  cambioDeImagen(event: any): void {
    // tslint:disable-next-line:max-line-length
    this.item.img = 'https://asistentedesalud.nayarit.gob.mx/IPLANAY_INMUEBLES/restful/index.php/Items/getImagenItem/' + event;
  }
}
