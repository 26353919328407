<div class="education layout-spacing ">
    <div class="widget-content widget-content-area">
        <h3>Solicitud para desoficialización</h3>



        <div class="clearfix"><strong>Nota:</strong> Los siguientes elementos quedaran oficializados hasta que
            el validor autorice</div>

        <div class="text-right">
            <button type="submit" class="btn btn-outline-primary" (click)="guardaSolicitud()"><i class="fa fa-save"
                    aria-hidden="true"></i>
                Guardar Solicitud</button>
            <button type="submit" class="btn btn-outline-danger" (click)="cancelarDesoficializacion()"><i
                    class="fa fa-trash" aria-hidden=" true"></i>
                Cancelar Solicitud</button>

        </div>

        <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">Motivos</label>
            <textarea class="form-control form-control-sm" id="exampleFormControlTextarea1" rows="4"
                [(ngModel)]="motivo" name="motivo"></textarea>
        </div>
        <hr>
        <h4>Elementos seleccionados</h4>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Imagen</th>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Descripcion</th>
                        <th>Clave Catastral</th>
                        <th>Domicilio</th>
                        <!-- <th>Notas</th>
                        <th>Estatus</th>
                        <th>Observaciones</th> -->
                        <th></th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listaDesoficializar; let i = index;">



                        <td>
                            <!-- <img *ngIf="item.imagenes[0].idimagen !== null" [src]="'https://asistentedesalud.nayarit.gob.mx/IPLANAY_INMUEBLES/restful/index.php/Items/getImagenItem/' + item.imagenes[0].idimagen" alt="Imagen no Disponible" height="100"> -->
                            <img *ngIf="item.imagenes[0].idimagen !== null"
                                [src]="'https://inmuebles.iplanay.gob.mx/restful/index.php/Items/getImagenItem/' + item.imagenes[0].idimagen"
                                alt="Imagen no Disponible" height="100">
                            <img *ngIf="item.imagenes[0].idimagen === null" src="assets/img/SIN-IMAGEN.jpeg"
                                alt="Imagen no Disponible" height="100">
                        </td>
                        <td>{{item.iditem}}</td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.descripcion}}</td>
                        <td>{{item.clave_catastral}}</td>
                        <td>{{item.domicilio}}</td>

                        <!-- <td>
                            <strong>Cargado por: </strong> <small>{{item.nombre_usuario}}</small>
                            <div *ngIf="item.validado !== '0'">
                                <br><strong>Validación Interna: </strong> <small>{{item.validado1_nombre}}</small>
                                <div *ngIf="item.validado2 !== '0'"><br><strong>Validación Externa: </strong>
                                    <small>{{item.validado2_nombre}}</small>
                                </div>

                            </div>
                        </td>
                       
                        <td>{{item.idestatus}} - {{item.estatus}}</td>
                        <td>{{item.observaciones}}</td> -->
                        <td>
                            <button class="btn btn-outline-danger" (click)="eliminaElemento(item)"><i
                                    class="fa fa-trash"></i> Eliminar</button>
                        </td>


                    </tr>
                </tbody>
            </table>
        </div>


    </div>


</div>