import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analisis-mueble',
  templateUrl: './analisis-mueble.component.html'
})
export class AnalisisMuebleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
