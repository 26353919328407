import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Item } from '../../models/Item';
import { ItemClass } from '../../clases/items.class';
import { Usuario } from '../../models/Usuario';

@Component({
  selector: 'app-identificacion',
  templateUrl: './identificacion.component.html'
})
export class IdentificacionComponent implements OnInit, OnChanges {
  @Input() item: Item;
  @Input() opt = '0';
  @Input() supervisor2 = '0';

  usuario: Usuario;

  corrientes: string[] = ['Art Decó', 'Art Nouveau', 'Barroco', 'Colonial', 'Ecléctico', 'Funcionalista', 'Internacional', 'Moderna', 'Neoclásica', 'Neocolonial', 'Neogótica', 'Neoindigenista', 'Neorrenacimiento', 'Posmoderno', 'Racionalista', 'Regionalista', 'Vernácula', 'N/D'];
  dataciones: string[] = ['Siglo XVIII', 'Siglo XIX', 'Siglo XX', 'N/D'];

  constructor(public objItem: ItemClass) {
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));

  }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    this.opt = JSON.parse(localStorage.getItem('opt'));


  }

  modificarIdentificacion(): any{
    this.objItem.modificarIdentificacionItem(this.item);
  }

}
