import { Component, OnInit } from '@angular/core';

declare function init_loader();
declare function init_plugins();
declare function init_custom();
declare function init_dash_1();
declare function initPerfect(): any;

@Component({
  selector: 'app-contenedor-principal',
  templateUrl: './contenedor-principal.component.html'
})
export class ContenedorPrincipalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    init_plugins();
    init_custom();
    init_dash_1();
    initPerfect();
  }

}
