import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Usuario } from 'src/app/models/Usuario';
import { Tipo } from '../../models/Tipo';

declare let App: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, AfterViewInit {

  usuario: Usuario;

  constructor(private router: Router) {
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {
    //App.init();
  }

  ngAfterViewInit() {
    //App.init();
  }

  verListado(tipo: Tipo) {
    localStorage.setItem('tipoActual', JSON.stringify(tipo));
    this.router.navigate(['/listado', tipo.tipo]).then(() => {
      window.location.reload();
    });
  }

}
