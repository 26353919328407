import { Component, OnInit } from '@angular/core';
import { Item } from '../../models/Item';

@Component({
  selector: 'app-desofizializar',
  templateUrl: './desofizializar.component.html'
})
export class DesofizializarComponent implements OnInit {

  items: Item[] = [];

  constructor() {
    this.items = JSON.parse(localStorage.getItem("listaDesoficializar"));
    console.log(this.items);
  }

  ngOnInit(): void {
  }

}
