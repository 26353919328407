<form ngNativeValidate (submit)='modificarClasificacion()'>

    <div class="form-row mb-4">
        <div class="form-group col-md-6">
            <label for="inputState">Clasificación Del Inmueble</label>
            <select id="inputState" class="form-control" name='clasificacion' #clasificacion [(ngModel)]="item.clasificacion.clasificacion" [disabled]=" opt == '1' ">
            <option *ngFor="let cla of clasificaciones"  [value]='cla'>{{cla}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
            <label for="inputState">Carácter</label>
            <select id="inputState" class="form-control" name='caracter' #caracter [(ngModel)]="item.clasificacion.caracter" [disabled]=" opt == '1' ">
            <option *ngFor="let car of caracteres"  [value]='car'>{{car}}</option>
          </select>
        </div>
    </div>

    <div class="form-row mb-4">
        <div class="form-group col-md-6">
            <label for="inputState">Nivel Máximo De Intervención</label>
            <select id="inputState" class="form-control" name='nivel_intervencion' #nivel_intervencion [(ngModel)]="item.clasificacion.nivel_intervencion" [disabled]=" opt == '1' ">
          <option *ngFor="let niv of niveles"  [value]='niv'>{{niv}}</option>
        </select>
        </div>
        <div class="form-group col-md-6">
            <label for="inputState">Estatus legal</label>
            <select id="inputState" class="form-control" name='estatus_legal' #estatus_legal [(ngModel)]="item.clasificacion.estatus_legal" [disabled]=" opt == '1' ">
          <option *ngFor="let es of estatus"  [value]='es'>{{es}}</option>
        </select>
        </div>

    </div>
    <div class="form-row mb-4">
        <div class="form-group col-md-12">
            <label for="inputState">Observaciones</label>
            <textarea class="form-control" name="observaciones" id="" rows="3" [(ngModel)]="item.clasificacion.observaciones" [disabled]=" opt == '1' "></textarea>
        </div>
    </div>

    <button *ngIf=" opt != '1' " type="submit" class="btn btn-primary mt-3">Guardar</button>
</form>
