import { Component, OnInit } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Respuesta } from '../../models/Respuesta';
import Swal from 'sweetalert2';
import { Solicitud } from '../../models/Solicitud';

@Component({
  selector: 'app-solicitudes-desoficializar',
  templateUrl: './solicitudes-desoficializar.component.html'
})
export class SolicitudesDesoficializarComponent implements OnInit {

  resp: Respuesta;
  solicitud: Solicitud;
  solicitudes: Solicitud[] = [];

  constructor(private _itemService: ItemService) {
    this.resp = new Respuesta();
    this.solicitud = new Solicitud();
  }

  ngOnInit(): void {
    this.getSolicitudes();
  }

  getSolicitudes(): void {

    this._itemService.getSolicitudes().subscribe(

      data => {
        this.resp = data;
        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          this.solicitudes = this.resp.data;
          console.log(this.solicitudes);
        }
      },
      err => {
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }

  rechazarAutorizarSolicitud(idsolicitud: string, estatus: string, ids: string) {
    //console.log(this.motivo);

    this._itemService.rechazarSolicitudDesoficialiar(idsolicitud, estatus, ids).subscribe(
      resp => {
        this.resp = resp;
        if (this.resp.error) {
          Swal.fire({
            icon: 'error',
            title: 'Rechazar Solicitud',
            text: this.resp.mensaje
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Rechazar Solicitud',
            text: this.resp.mensaje
          });
        }
        this.getSolicitudes();
      }
    );

  }


}
