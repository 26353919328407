import { Injectable } from '@angular/core';
import { ItemService } from '../services/item.service';
import { Respuesta } from '../models/Respuesta';
import { Item } from '../models/Item';
import { Tipo } from '../models/Tipo';

import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class ItemClass {

  resp: Respuesta;
  items: Item[] = [];
  item: Item;
  tipo: Tipo;
  img: string;

  constructor(private _itemService: ItemService) {
    this.resp = new Respuesta();
    this.tipo = new Tipo();
    this.item = new Item();
  }

  public getItems(): void {
    this._itemService.getItems(this.tipo, localStorage.getItem("txtVPrincipal"), localStorage.getItem("estatusC")).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          this.items = this.resp.data;
          console.log(this.items);
        }

      },
      err => {
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }

  public getItem(item: Item): void {
    // alert('getItem()');
    this._itemService.getItem(item.iditem).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          this.item = this.resp.data[0];
          //console.log(this.item);
          item = this.resp.data[0];
          //alert(this.item.imagenes.length);
          if (this.item.imagenes[0].idimagen !== null) {
            // tslint:disable-next-line:max-line-length
            this.img = 'https://asistentedesalud.nayarit.gob.mx/IPLANAY_INMUEBLES/restful/index.php/Items/getImagenItem/' + this.item.imagenes[0].idimagen;
          } else {
            this.img = 'assets/img/SIN-IMAGEN.jpeg';
          }
          // console.log(this.item);
        }

      },
      err => {
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }

  public guardarItem(): Item {
    this._itemService.item = this.item;
    this._itemService.guardarItem().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          this.item = this.resp.data[0];
          // console.log(this.items);
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }

  public modificarItem(): Item {
    this._itemService.item = this.item;
    this._itemService.modificarItem().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Datos Generales',
            this.resp.mensaje,
            'success'
          );
          this.item = this.resp.data[0];
          // console.log(this.items);
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }



  public modificarEstadoConservacionItem(): Item {
    this._itemService.item = this.item;
    this._itemService.modificarEstadoConservacion().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Estado de Conservación',
            this.resp.mensaje,
            'success'
          );
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }


  public modificarIdentificacionItem(item: Item): Item {
    this._itemService.item = item;
    this._itemService.modificarIdentificacion().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Identificación',
            this.resp.mensaje,
            'success'
          );
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }

  public modificarClasificacionItem(item: Item): Item {
    this._itemService.item = item;
    this._itemService.modificarClasificacion().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Identificación',
            this.resp.mensaje,
            'success'
          );
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }

  public modificarCaracteristicasItem(item: Item): Item {
    this._itemService.item = item;
    this._itemService.modificarCaracteristicas().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Identificación',
            this.resp.mensaje,
            'success'
          );
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }

  public modificarLocalizacionItem(item: Item): Item {
    this._itemService.item = item;
    this._itemService.modificarLocalizacion().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Identificación',
            this.resp.mensaje,
            'success'
          );
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }


  public modificarCoordenadasItem(iditem: string, lat: string, lon: string): Item {
    // this._itemService.item = item;
    this._itemService.modificarCoordenadas(iditem, lat, lon).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Modificar!',
            'Se han modificado las coordenadas.',
            'success'
          );
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }

  public validarRegistro(iditem, supervision): boolean {
    // this._itemService.item = item;
    this._itemService.validarRegistro(iditem, supervision).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
          return false;
        } else {
          Swal.fire(
            'Modificar!',
            this.resp.mensaje,
            'success'
          );
          return true;
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return true;
  }

  public habilitarRegistro(item: Item, supervision): boolean {
    // this._itemService.item = item;
    this._itemService.habilitarRegistro(item, supervision).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
          return false;
        } else {
          Swal.fire(
            'Modificar!',
            this.resp.mensaje,
            'success'
          );
          item.validado = '0';
          return true;
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return true;
  }

  public cerrarCaptura(iditem, supervision): boolean {
    // this._itemService.item = item;
    this._itemService.cerrarCaptura(iditem, supervision).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
          return false;
        } else {
          Swal.fire(
            'Modificar!',
            this.resp.mensaje,
            'success'
          );
          return true;
        }

      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return true;
  }


  public getImagenesJSON(item: Item): Item {
    this._itemService.item = item;
    this._itemService.getImagenesJSON().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          Swal.fire(
            'Identificación',
            this.resp.mensaje,
            'success'
          );
          this.item.imagenes = this.resp.data;
        }
      },
      err => {
        console.log(err);
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
    return this.item;
  }
}
