import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identificacion-mueble',
  templateUrl: './identificacion-mueble.component.html'
})
export class IdentificacionMuebleComponent implements OnInit {

  categoria: { categoria: string, subcategorias: { subcategoria: string, opciones: string[] }[] } = { categoria: '', subcategorias: [{ subcategoria: '', opciones: [''] }] };
  subcategoria: { subcategoria: string, opciones: string[] } = { subcategoria: '', opciones: [''] };
  opciones: string[] = [''];

  categorias: { categoria: string, subcategorias: { subcategoria: string, opciones: string[] }[] }[] = [
    {
      categoria: 'Bienes de carácter arqueológico',
      subcategorias: [
        {
          subcategoria: 'Madera',
          opciones: ['Banco', 'Silla', 'Bastón', 'Aguja', 'Lanzadera']
        },
        {
          subcategoria: 'Lítico',
          opciones: ['Estatua', 'Cuenta de collares', 'Máscara']
        },
        {
          subcategoria: 'Cerámica',
          opciones: ['Figura antropomorfa', 'Figura zoomorfa', 'Urna funerari', 'Recipiente', 'Instrumentos musicales', 'Sellos', 'Rodillos', 'Ralladores']
        },
        {
          subcategoria: 'Orfebrería',
          opciones: ['Pendientes', 'Pectorales', 'Narigueras', 'Collares', 'Máscaras', 'Cabezas de bastón', 'Discos', 'Agujas', 'Botones']
        },
        {
          subcategoria: 'Textil',
          opciones: ['Mantas', 'Mochilas', 'Indumentaria']
        },
        {
          subcategoria: 'Arte rupestre',
          opciones: ['Pictografías', 'Petroglifos']
        },
        {
          subcategoria: 'Óseo',
          opciones: ['Collares', 'Aguja', 'Punzones', 'Cucharas']
        }
      ]
    },
    {
      categoria: 'Bienes de carácter etnográfico',
      subcategorias: [
        {
          subcategoria: 'Madera',
          opciones: ['Banco', 'Silla', 'Bastón', 'Aguja', 'Lanzadera']
        },
        {
          subcategoria: 'Lítico',
          opciones: ['Estatua', 'Cuenta de collares', 'Máscara']
        },
        {
          subcategoria: 'Cerámica',
          opciones: ['Figura antropomorfa', 'Figura zoomorfa', 'Urna funerari', 'Recipiente', 'Instrumentos musicales', 'Sellos', 'Rodillos', 'Ralladores']
        },
        {
          subcategoria: 'Orfebrería',
          opciones: ['Pendientes', 'Pectorales', 'Narigueras', 'Collares', 'Máscaras', 'Cabezas de bastón', 'Discos', 'Agujas', 'Botones']
        },
        {
          subcategoria: 'Textil',
          opciones: ['Mantas', 'Mochilas', 'Indumentaria']
        },
        {
          subcategoria: 'Arte rupestre',
          opciones: ['Pictografías', 'Petroglifos']
        },
        {
          subcategoria: 'Óseo',
          opciones: ['Collares', 'Aguja', 'Punzones', 'Cucharas']
        }
      ]
    },
    {
      categoria: 'Bienes de carácter artístico',
      subcategorias: [
        {
          subcategoria: 'Pintura',
          opciones: ['Al óleo', 'Al temple', 'Acuarela y gouache', 'Pastel', 'Encáustica', 'Mural', 'Acrílica', 'Mixta']
        },
        {
          subcategoria: 'Escultura',
          opciones: ['Tallada', 'Fundida', 'Moldeada', 'Relieve', 'Constructiva']
        },
        {
          subcategoria: 'Grabado',
          opciones: ['Agua fuerte', 'Aguatinta', 'Punta seca', 'Litografía', 'Xilografía', 'Serigrafía', 'Agua fuerte en base blanda', 'Mixta']
        },
        {
          subcategoria: 'Dibujo',
          opciones: ['Pastel', 'Carboncillo', 'Puntas metálicas', 'Lápiz', 'Pluma', 'Pincel', 'Tintas']
        },
        {
          subcategoria: 'Artes gráficas',
          opciones: ['Carteles', 'Afiches', 'Ilustraciones', 'Fotografía artística']
        },
        {
          subcategoria: 'Vitral',
          opciones: ['Vidrio de color', 'Vidrio pintado']
        },
        {
          subcategoria: 'Tejidos artísticos',
          opciones: ['Gobelinos']
        },
        {
          subcategoria: 'Obras espacio-temporales',
          opciones: ['Instalaciones', 'Performances']
        },
        {
          subcategoria: '',
          opciones: ['', '']
        },
        {
          subcategoria: '',
          opciones: ['', '']
        }
      ]
    },
    {
      categoria: 'Bienes de carácter utilitario',
      subcategorias: [
        {
          subcategoria: 'Relacionados con el culto',
          opciones: ['Copón', 'Cáliz', 'Custodia', 'Incensario', 'Naveta', 'Patena', 'Vinajera']
        },
        {
          subcategoria: 'Ornamentos litúrgicos e indumentaria religiosa',
          opciones: ['Hábito religioso', 'Báculo', 'Mitra', 'Toca', 'Escapulario', 'Casulla', 'Velos de altar', 'Paño mortuori', 'Conopeo']
        },
        {
          subcategoria: 'Mobiliario',
          opciones: ['Cancel', 'Atril', 'Confesionario', 'Baldaquino', 'Retablo', 'Arca', 'Cama', 'Bargueño', 'Escritorio', 'Florero', 'Reloj']
        },
        {
          subcategoria: 'De uso doméstico o cotidiano',
          opciones: ['Olla', 'Cuchara', 'Aguamanil', 'Vajilla', 'Cubrelechos', 'Sábana', 'Funda']
        },
        {
          subcategoria: 'Uso personal',
          opciones: ['Cepillo', 'Elementos de tocador', 'Reloj de pulsera', 'Anillo', 'Brazalete', 'Camafeo', 'Diadema', 'Mancuernilla']
        },
        {
          subcategoria: 'Indumentaria',
          opciones: ['Camisa', 'Casaca', 'Corpiño', 'Gorro', 'Sandalia', 'Túnica', 'Uniforme', 'Zapatos']
        },
        {
          subcategoria: 'Instrumentos musicales',
          opciones: ['Marimba', 'Flauta', 'Guitarra', 'Violín', 'Violonchelo', 'Timbal']
        },
        {
          subcategoria: 'Armas',
          opciones: ['Arcabuz', 'Bayoneta', 'Cerbatana', 'Florete', 'Flecha', 'Hacha', 'Espada']
        },
        {
          subcategoria: 'Condecoraciones, trofeos y premios',
          opciones: ['Cruz', 'Medalla', 'Orden militar']
        },
        {
          subcategoria: 'Banderas y escudos',
          opciones: ['Bandera', 'Escudo']
        },
        {
          subcategoria: 'Utensilios, herramientas de trabajo, máquina',
          opciones: ['Instrumental quirúrgico', 'Instrumentos de medición', 'Elementos de escritorio', 'Martillo', 'Cincel', 'Alicate', 'Máquina de coser']
        },
        {
          subcategoria: 'Vehículos',
          opciones: ['Bicicleta', 'Canoa', 'Automóvil', 'Tren', 'Locomotora']
        },
        {
          subcategoria: 'Accesorios',
          opciones: ['Aldaba', 'Candado', 'Estoperol', 'Campana', 'Pila bautismal']
        },
        {
          subcategoria: 'Elementos relacionados con inmuebles',
          opciones: ['Cancel', 'Ventana', 'Artesonado', 'Camarín', 'Baranda', 'Veleta', 'Columna', 'Rosetón']
        },
        {
          subcategoria: 'Usos funerarios',
          opciones: ['Urnas', 'Sepulcros']
        },
        {
          subcategoria: 'Maquetas y modelos',
          opciones: ['De objetos tridimensionales', 'Aeromodelo', 'Globo terráqueo', 'Modelo cartográfico']
        },
        {
          subcategoria: 'Numismático y filatélico',
          opciones: ['Monedas', 'Estampillas', 'Sellos de correo']
        }
      ]
    },
    {
      categoria: 'Bienes de carácter documental',
      subcategorias: [
        {
          subcategoria: 'Documento histórico',
          opciones: ['Álbum', 'Apunte', 'Carta', 'Cédula real', 'Diploma', 'Registro']
        },
        {
          subcategoria: 'Archivo administrativo',
          opciones: ['Acto administrativo', 'Censo', 'Contrato', 'Estudio', 'Expediente', 'Ficha', 'Informe', 'Investigación', 'Inventario', 'Libro de cuentas', 'Libro parroquial', 'Protocolo notarial', 'Proyecto', 'Correspondencia']
        },
        {
          subcategoria: 'Gráfico',
          opciones: ['Afiche', 'Aerofotografía', 'Diapositiva', 'Plano', 'Reproducción fotomecánica', 'Diagrama', 'Fotografía', 'Negativo', 'Placa', 'Radiografía']
        },
        {
          subcategoria: 'Cartográfico',
          opciones: ['Mapa', 'Atlas', 'Globo']
        },
        {
          subcategoria: 'Monográfico',
          opciones: ['Libro', 'Folleto', 'Pliego suelto']
        },
        {
          subcategoria: 'Seriado',
          opciones: ['Periódico', 'Revista', 'Anuario', 'Memoria']
        },
        {
          subcategoria: 'Sonoro y musical',
          opciones: ['Carrete de cinta sonora', 'Cartucho sonor', 'Casete sonor', 'Grabación en películ', 'Banda sonor', 'Disco sonor', 'Disco compacto']
        },
        {
          subcategoria: 'Audiovisual',
          opciones: ['Obra de cinematografía', 'Producción de video']
        }
      ]
    },
    {
      categoria: 'Bienes de carácter científico',
      subcategorias: [
        {
          subcategoria: 'Bienes de carácter científico',
          opciones: ['Especímenes biológicos', 'Especímenes geológicos', 'Cuerpos astronómicos']
        }
      ]
    },
    {
      categoria: 'Monumentos en espacio público',
      subcategorias: [
        {
          subcategoria: 'Monumentos en espacio público',
          opciones: ['Escultura', 'Busto', 'Columna', 'Fuente', 'Placa', 'Obelisco']
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  selectCategorias(index: number) {

    this.categoria = this.categorias[index];

  }

  selectSubcategorias(index: number) {

    this.subcategoria = this.categoria.subcategorias[index];

  }


}
