<h1>Valoración y significación cultural del bien</h1>

<form>

    <div class="form-group">
        <label for="exampleInputPassword1">Valor histórico</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Antigüedad</option>
            <option value="">Testimonial</option>
            <option value="">De documentación</option>
            <option value="">Referencial</option>
        </select>
    </div>
    <div class="form-group">
        <label for="exampleInputPassword1">Valor artístico</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Relevancia de autoría</option>
            <option value="">Calidad estética</option>
            <option value="">Singularidad</option>
            <option value="">Representatividad</option>
        </select>
    </div>
    <div class="form-group">
        <label for="exampleInputPassword1">Valor social</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Identitario</option>
            <option value="">Continuidad de su uso</option>
            <option value="">Educativo</option>
        </select>
    </div>
    <div class="form-group">
        <label for="exampleInputPassword1">Valor material</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Naturaleza</option>
            <option value="">Integridad</option>
            <option value="">Conservación</option>
            <option value="">Vulnerabilidad</option>
        </select>
    </div>


    <button type="button" class="btn btn-primary">Guardar</button>
</form>