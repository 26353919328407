import { Injectable } from '@angular/core';
import { ItemService } from '../services/item.service';
import { Respuesta } from '../models/Respuesta';
import { Tipo } from '../models/Tipo';

import Swal from 'sweetalert2';
import { LocalizacionService } from '../services/localizacion.service';
import { Localidad } from '../models/Localidad';


@Injectable({
  providedIn: 'root'
})
export class LocalidadClass {

  resp: Respuesta;
  localidades: Localidad[] = [];

  constructor(private _localizacionService: LocalizacionService){
    this.resp = new Respuesta();
  }

  public getLocalidades(idmunicipio: string) {
    this._localizacionService.getLocalidades(idmunicipio).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        }else{
          this.localidades = this.resp.data;
          // console.log(this.localidades);
        }

      },
      err => {
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }
}
