
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';

import { Respuesta } from '../models/Respuesta';
import { Item } from '../models/Item';
import { Localidad } from '../models/Localidad';


@Injectable({
  providedIn: 'root'
})
export class LocalizacionService {
  url = '';
  resp: Respuesta;
  item: Item;
  localidades: Localidad[] = [];
  headers: HttpHeaders = new HttpHeaders();


  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.item = new Item();
    this.resp = new Respuesta();
  }

  getLocalidades(idmunicipio: string): Observable<any> {
    try {
      let params = new HttpParams()
      .append('idestado', '18')
      .append('idmunicipio', idmunicipio);

      return this.http.post<Respuesta>(this.url + 'Localidades/getLocalidades', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          //console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
