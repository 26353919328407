<div class="col-lg-12  layout-spacing">
    <div class="statbox widget box box-shadow">
        <div class="row">
            <div class="col-3">

                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a [routerLinkActive]="['active']" class="nav-link active" [id]="'informacion-tab'" data-toggle="pill" [href]='"#informacion"' role="tab" [attr.aria-controls]='"informacion"' aria-selected="true">INFORMACIÓN</a>
                    <a *ngFor="let modulo of tipo.modulos, let i=index;" [routerLinkActive]="['active']" class="nav-link" [id]="modulo.tab + '-tab'" data-toggle="pill" [href]='"#"+modulo.tab' role="tab" [attr.aria-controls]='modulo.tab' aria-selected="true">{{modulo.modulo | uppercase}}</a>
                </div>

            </div>

            <div class="col-9">
                <div class="tab-content" id="v-pills-tabContent">
                    <div [routerLinkActive]="['show', 'active']" class="tab-pane fade show active" [id]="'informacion'" role="tabpanel" [attr.aria-labelledby]="'informacion-tab'">
                        <app-nuevo-item [item]='item'></app-nuevo-item>
                    </div>
                    <div [routerLinkActive]="['show', 'active']" class="tab-pane fade" [id]="modulo.tab" role="tabpanel" [attr.aria-labelledby]="modulo.tab+'-tab'" *ngFor="let modulo of tipo.modulos, let i = index">
                        <h4>{{modulo.modulo}}</h4>
                        <app-localizacion *ngIf="modulo.tab==='localizacion'" [item]='item' [opt]='opt'></app-localizacion>
                        <app-imagen *ngIf="modulo.tab==='imagen'" [item]='item'></app-imagen>
                        <app-caractaristicas *ngIf="modulo.tab==='caracteristicas'" [item]='item'></app-caractaristicas>
                        <app-estado *ngIf="modulo.tab==='estado'" [item]='item'></app-estado>
                        <app-identificacion *ngIf="modulo.tab==='identificacion'" [item]='item'></app-identificacion>
                        <app-clasificacion *ngIf="modulo.tab==='clasificacion'" [item]='item'></app-clasificacion>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<!-- <div class="col-lg-12  layout-spacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Vertical</h4>
                </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">
            <div id="pill-vertical">
                <h3>Keyboard</h3>
                <section>
                    <p>Try the keyboard navigation by clicking arrow left or right!</p>
                </section>
                <h3>Effects</h3>
                <section>
                    <p>Wonderful transition effects.</p>
                </section>
                <h3>Pager</h3>
                <section>
                    <p>The next and previous buttons help you to navigate through your content.</p>
                </section>
            </div>


        </div>
    </div>
</div> -->
