
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';

import { Usuario } from '../models/Usuario';
import { Respuesta } from '../models/Respuesta';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  url = '';
  resp: Respuesta;
  usuario: Usuario;
  headers: HttpHeaders = new HttpHeaders();


  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.usuario = new Usuario();
    this.resp = new Respuesta();
  }

  getUsuarios(): Observable<any> {
    try {
      let params = new HttpParams()
      .append('user_id', '2');

      return this.http.post<Respuesta>(this.url + 'Expedientes_taxi/getExpedientes', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  login(): Observable<Respuesta> {
    try {
      let params = new HttpParams()
      .append('email', this.usuario.email)
      .append('psw', this.usuario.psw);

      return this.http.post<Respuesta>(this.url + 'Users/login', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          //console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarContra(psw: string): Observable<Respuesta> {
    try {
      let params = new HttpParams()
      .append('idusuario', this.usuario.idusuario)
      .append('psw', psw);

      return this.http.post<Respuesta>(this.url + 'Users/modificarContra', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          //console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
