<div class="education layout-spacing ">
    <div class="widget-content widget-content-area">
        <h3>Listado</h3>
        <h4><small>{{tipo.tipo}}</small></h4>

        <div class="accordion" id="accordionExample">

            <div class="card">
                <div class="card-header" id="headingThree">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Filtros
                        </button>
                    </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">Vialidad Principal</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control form-control-sm" id="staticEmail"
                                        placeholder="Ej. Av. Mexico" name="txtVPrincipal" [(ngModel)]="txtVPrincipal">
                                </div>

                                <button type="button" class="btn btn-primary mb-2"
                                    (click)="rerender()">Consultar</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>


        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map: -->


        <!-- <button type="button" class="btn btn-primary mb-2 mr-2" (click)='descargaPDF2()'><i class="fa fa-plus-circle" aria-hidden="true"></i> PDF</button> -->
        <div class="text-right">
            <button *ngIf="tipo.agregar === '1'" type="button" class="btn btn-primary mb-2 mr-2"
                (click)='nuevoItem(tipo)'><i class="fa fa-plus-circle" aria-hidden="true"></i> Nuevo</button>
            <button type="button" class="btn btn-primary mb-2 mr-2" (click)='verMapa(tipo)'><i class="fa fa-map"
                    aria-hidden="true"></i> Vista Mapa</button>
            <button *ngIf="tipo.supervision === '1' && !desoficializar" type="button" class="btn btn-danger mb-2 mr-2"
                (click)="consultaOficializados()"><i class="fa fa-list" aria-hidden="true"></i> Desoficializar
                Centros</button>
            <button *ngIf="tipo.supervision === '1' && desoficializar" type="button" class="btn btn-warning mb-2 mr-2"
                (click)="cancelarSolicitud()"><i class="fa fa-list" aria-hidden="true"></i> Cancelar
                desoficialización</button>

            <button *ngIf="tipo.supervision === '2' && !desoficializar" type="button" class="btn btn-danger mb-2 mr-2"
                (click)="consultaSolicitudes()"><i class="fa fa-list" aria-hidden="true"></i> Solicitudes
                Pendientes</button>
        </div>

        <app-loader *ngIf="cargando"></app-loader>
        <!-- <app-loading *ngIf="cargando"></app-loading> -->

        <h2 *ngIf="desoficializar">Selecciona Inmuebles para desoficializar <small>({{itemsDesoficializar.length}}
                elemento(s) seleccionado(s))</small></h2>
        <button *ngIf="desoficializar && itemsDesoficializar.length > 0" type="button" class="btn btn-primary mb-2 mr-2"
            (click)='guardaDesoficializacion()'><i class="fa fa-save" aria-hidden="true"></i> Guardar Solicitud</button>

        <div class="table-responsive">
            <table id="tablaLista" #tablaLista datatable class="row-border hover" [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger">
                <thead>
                    <tr>
                        <th>Imagen</th>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Descripcion</th>
                        <th>Clave Catastral</th>
                        <th>Domicilio</th>
                        <!-- <th>Latitud</th>
                      <th>Longitud</th> -->
                        <th>Notas</th>
                        <!-- <th>Fecha de Carga</th> -->
                        <th>Estatus</th>
                        <!-- <th>Observaciones</th> -->
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items; let i = index;">



                        <td>
                            <!-- <img *ngIf="item.imagenes[0].idimagen !== null" [src]="'https://asistentedesalud.nayarit.gob.mx/IPLANAY_INMUEBLES/restful/index.php/Items/getImagenItem/' + item.imagenes[0].idimagen" alt="Imagen no Disponible" height="100"> -->
                            <img *ngIf="item.imagenes[0].idimagen !== null"
                                [src]="'https://inmuebles.iplanay.gob.mx/restful/index.php/Items/getImagenItem/' + item.imagenes[0].idimagen"
                                alt="Imagen no Disponible" height="100">
                            <img *ngIf="item.imagenes[0].idimagen === null" src="assets/img/SIN-IMAGEN.jpeg"
                                alt="Imagen no Disponible" height="100">
                        </td>
                        <td>{{item.iditem}}</td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.descripcion}}</td>
                        <td>{{item.clave_catastral}}</td>
                        <td>{{item.domicilio}}</td>
                        <!-- <td>{{item.latitud | number}}</td>
                        <td>{{item.longitud | number}}</td> -->
                        <td>
                            <strong>Cargado por: </strong> <small>{{item.nombre_usuario}}</small>
                            <div *ngIf="item.validado !== '0'">
                                <br><strong>Validación Interna: </strong> <small>{{item.validado1_nombre}}</small>
                                <div *ngIf="item.validado2 !== '0'"><br><strong>Validación Externa: </strong>
                                    <small>{{item.validado2_nombre}}</small>
                                </div>

                            </div>
                        </td>
                        <!-- <td>{{item.fecha_creacion | date: 'medium'}}</td> -->
                        <td>{{item.idestatus}} - {{item.estatus}}</td>
                        <!-- <td>{{item.observaciones}}</td> -->

                        <td>
                            <!-- <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-outline-primary" (click)='detalleItem(item, "1")'><i class="fa fa-info-circle" aria-hidden="true"></i> Detalles</button>
                                <button *ngIf="item.validado === '0' && tipo.modificar === '1'" type="button" class="btn btn-outline-warning" (click)='detalleItem(item, "2")'><i class="fa fa-edit" aria-hidden="true"></i> Editar</button>
                                <button *ngIf="item.validado === '0' && tipo.eliminar === '1'" type="button" class="btn btn-outline-danger" (click)='eliminarItem(item)'><i class="fa fa-trash" aria-hidden="true"></i> Eliminar</button>
                            </div> -->
                            <div class="btn-group">
                                <button type="button" class="btn btn-dark btn-sm">Opciones</button>
                                <button type="button" class="btn btn-dark btn-sm dropdown-toggle dropdown-toggle-split"
                                    id="dropdownMenuReference17" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false" data-reference="parent">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-chevron-down">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuReference17"
                                    *ngIf="tipo.supervision === '0'">

                                    <button type="button" class="dropdown-item" (click)='detalleItem(item, "1")'><i
                                            class="fa fa-info-circle" aria-hidden="true"></i> Detalles
                                        {{tipo.supervision}}</button>
                                    <button *ngIf="item.idestatus==='1' && tipo.modificar === '1'" type="button"
                                        class="dropdown-item" (click)='detalleItem(item, "2")'><i class="fa fa-edit"
                                            aria-hidden="true"></i> Editar</button>
                                    <button *ngIf="item.idestatus==='1' && tipo.eliminar === '1'" type="button"
                                        class="dropdown-item" (click)='eliminarItem(item)'><i class="fa fa-trash"
                                            aria-hidden="true"></i> Anular</button>
                                </div>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuReference17"
                                    *ngIf="tipo.supervision === '1'">

                                    <button type="button" class="dropdown-item" (click)='detalleItem(item, "1")'><i
                                            class="fa fa-info-circle" aria-hidden="true"></i> Detalles
                                        {{tipo.supervision}}</button>
                                    <button *ngIf="item.idestatus==='2' && tipo.modificar === '1'" type="button"
                                        class="dropdown-item" (click)='detalleItem(item, "2")'><i class="fa fa-edit"
                                            aria-hidden="true"></i> Editar</button>
                                    <button *ngIf="item.idestatus==='2' && tipo.eliminar === '1'" type="button"
                                        class="dropdown-item" (click)='eliminarItem(item)'><i class="fa fa-trash"
                                            aria-hidden="true"></i> Anular</button>
                                    <button *ngIf="item.idestatus==='4'" type="button" class="dropdown-item"
                                        (click)='solicitarDesoficialarUno(item)'><i class="fa fa-trash"
                                            aria-hidden="true"></i>
                                        Solicitar Desoficializar</button>
                                </div>

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuReference17"
                                    *ngIf="tipo.supervision === '2'">

                                    <button type="button" class="dropdown-item" (click)='detalleItem(item, "1")'><i
                                            class="fa fa-info-circle" aria-hidden="true"></i> Detalles
                                        {{tipo.supervision}}</button>
                                    <button *ngIf="item.idestatus==='3' && tipo.modificar === '1'" type="button"
                                        class="dropdown-item" (click)='detalleItem(item, "2")'><i class="fa fa-edit"
                                            aria-hidden="true"></i> Editar</button>
                                    <button *ngIf="item.idestatus==='3' && tipo.eliminar === '1'" type="button"
                                        class="dropdown-item" (click)='eliminarItem(item)'><i class="fa fa-trash"
                                            aria-hidden="true"></i> Anular</button>
                                </div>
                            </div>
                            <div *ngIf="desoficializar" class="form-check text-center">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    (change)="selectItem(i)">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Desoficializar
                                </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>