import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/Usuario';

declare const App: any;

declare const $: any;
import Swal from 'sweetalert2';
import { Respuesta } from '../../models/Respuesta';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html'
})
export class PerfilComponent implements OnInit {

  usuario: Usuario;
  resp: Respuesta;

  contraActual: string = '';
  nuevaContra: string = '';
  nuevaContraC: string = '';

  constructor(private _usuarioServise: UsuarioService, private router: Router) {
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.resp = new Respuesta();
  }

  ngOnInit(): void {

  }


  modificaContra( ){

      $('#modalContraActual').modal('show');

  }

  validarContraActual(){
    $('#modalContraActual').modal('hide');
    $('#modalNuevaContra').modal('show');
  }

  login(){
    this._usuarioServise.usuario = this.usuario;
    this._usuarioServise.login().subscribe(
      data => {
        this.resp = data;
        console.log(this.resp);
        if(this.resp.error){
          $('#modalContraActual').modal('hide');
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          ).then(()=>{
            localStorage.clear();

            this.router.navigate(['login']);
          });

        }else{
          this.usuario = this.resp.data[0];
          localStorage.setItem('usuario', JSON.stringify(this.usuario));
          $('#modalContraActual').modal('hide');
          $('#modalNuevaContra').modal('show');

        }
      }
    );
  }

  guardarNuevaContra(){
    if(this.nuevaContra == this.nuevaContraC){
      this._usuarioServise.modificarContra(this.nuevaContra).subscribe(
        data => {
          this.resp = data;
          console.log(this.resp);
          if(this.resp.error){
            $('#modalNuevaContra').modal('hide');
            Swal.fire(
              'Error',
              this.resp.mensaje,
              'error'
            ).then(()=>{
              localStorage.clear();

              this.router.navigate(['login']);
            });

          }else{
            $('#modalNuevaContra').modal('hide');
            Swal.fire(
              'Actualizado',
              this.resp.mensaje,
              'success'
            ).then(()=>{
              localStorage.clear();

              this.router.navigate(['login']);
            });
            //this.usuario = this.resp.data[0];
            //localStorage.setItem('usuario', JSON.stringify(this.usuario));
            //$('#modalContraActual').modal('hide');
            //$('#modalNuevaContra').modal('show');

          }
        });
    }else{
      Swal.fire(
        'Error',
        'Las contraseñas no coinciden',
        'error'
      );
    }
  }

}
