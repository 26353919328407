import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Item } from '../../models/Item';
import { ItemClass } from '../../clases/items.class';

@Component({
  selector: 'app-clasificacion',
  templateUrl: './clasificacion.component.html'
})
export class ClasificacionComponent implements OnInit, OnChanges {
  @Input() item: Item;
  @Input() opt: string;
  @Input() supervisor2: string;

  clasificaciones: string[] = ['Inmueble de valor artístico ambiental', 'Inmueble de valor artístico relevante', 'Inmueble de valor histórico ambiental', 'Monumento histórico civil relevante', 'Monumento por determinación de ley', 'N/D'];
  caracteres: string[] = ['Excepcional', 'Modesto', 'Relevante', 'Representativa', 'Típico', 'N/D'];
  niveles: string[] = ['Adaptación controlada', 'Adecuación a imagen urbana', 'Conservación', 'Restauración especializada', 'Sustitución controlada', 'N/D'];
  estatus: string[] = ['Monumento catalogado por el INAH', 'Monumento decretado por el estado', 'Monumento catalogado por el INAH y Decretado por el estado', 'Suceptible a ser decretado por el estado', 'N/D'];

  constructor(public objItem: ItemClass) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    this.opt = JSON.parse(localStorage.getItem('opt'));
  }

  modificarClasificacion(): void{
    this.objItem.modificarClasificacionItem(this.item);

  }

}
