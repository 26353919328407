import { Usuario } from './../models/Usuario';

import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';

import { Respuesta } from '../models/Respuesta';
import { Item } from '../models/Item';
import { Tipo } from '../models/Tipo';


@Injectable({
  providedIn: 'root'
})
export class ItemService {
  url = '';
  resp: Respuesta;
  item: Item;
  headers: HttpHeaders = new HttpHeaders();

  usuario: Usuario;


  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.item = new Item();
    this.resp = new Respuesta();
    this.usuario = new Usuario();
  }

  cargarUsuario() {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  getItems(tipo: Tipo, txtVPrincipal: string, estatusC: string): Observable<any> {
    this.cargarUsuario();
    // console.log('Usuario: ' + this.usuario.idusuario);
    // console.log('Supervidor: ' + this.usuario.supervisor);
    try {
      const params = new HttpParams()
        .append('idtipo', tipo.idtipo)
        .append('vPrincipal', txtVPrincipal)
        .append('estatusC', estatusC)
        .append('idusuario', this.usuario.idusuario)
        .append('supervisor', tipo.supervision);


      console.log(params);

      return this.http.post<Respuesta>(this.url + 'Items/getItems', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  getItem(iditem: string): Observable<any> {
    try {
      let params = new HttpParams()
        .append('iditem', iditem);

      return this.http.post<Respuesta>(this.url + 'Items/getItem', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  getSolicitudes(): Observable<any> {
    try {
      let params = new HttpParams();

      return this.http.post<Respuesta>(this.url + 'Items/getSolicitudesDesoficializar', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  deleteItem(iditem: string): Observable<any> {
    try {
      let params = new HttpParams()
        .append('iditem', iditem);

      return this.http.post<Respuesta>(this.url + 'Items/eliminarItem', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          //console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  guardarItem(): Observable<any> {
    this.cargarUsuario();
    try {
      let params = new HttpParams()
        .append('idtipo', this.item.idtipo)
        .append('nombre', this.item.nombre)
        .append('descripcion', this.item.descripcion)
        .append('latitud', String(this.item.latitud))
        .append('longitud', String(this.item.longitud))
        .append('idusuario', this.usuario.idusuario)
        .append('idmunicipio', this.usuario.municipios[0].idmunicipio);

      // console.log(params);

      return this.http.post<Respuesta>(this.url + 'Items/guardarItem', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarItem(): Observable<any> {
    try {
      let params = new HttpParams()
        .append('iditem', this.item.iditem)
        .append('nombre', this.item.nombre)
        .append('descripcion', this.item.descripcion)
        .append('latitud', String(this.item.latitud))
        .append('longitud', String(this.item.longitud));

      return this.http.post<Respuesta>(this.url + 'Items/modificarItem', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          //console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarEstadoConservacion() {
    try {
      let params = new HttpParams()
        .append('iditem', this.item.iditem)
        .append('estado', this.item.conservacion.estado)
        .append('alteraciones', this.item.conservacion.alteraciones);

      return this.http.post<Respuesta>(this.url + 'Items/modificarEstadoConservacion', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          //console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarIdentificacion() {
    try {
      let params = new HttpParams()
        .append('iditem', this.item.iditem)
        .append('corriente', this.item.identificacion.corriente_artistica)
        .append('datacion', this.item.identificacion.datacion);

      return this.http.post<Respuesta>(this.url + 'Items/modificarIdentificacion', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarClasificacion() {
    try {
      let params = new HttpParams()
        .append('iditem', this.item.iditem)
        .append('caracter', this.item.clasificacion.caracter)
        .append('clasificacion', this.item.clasificacion.clasificacion)
        .append('estatus_legal', this.item.clasificacion.estatus_legal)
        .append('nivel_intervencion', this.item.clasificacion.nivel_intervencion)
        .append('observaciones', this.item.clasificacion.observaciones);

      return this.http.post<Respuesta>(this.url + 'Items/modificarClasificacion', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarCaracteristicas() {
    try {
      let params = new HttpParams()
        .append('iditem', this.item.iditem)
        .append('descripcion', this.item.caracteristica.descripcion)
        .append('habitada', this.item.caracteristica.habitada)
        .append('niveles', this.item.caracteristica.niveles)
        .append('regimen', this.item.caracteristica.regimen)
        .append('uso_actual', this.item.caracteristica.uso_actual)
        .append('uso_original', this.item.caracteristica.uso_original);

      return this.http.post<Respuesta>(this.url + 'Items/modificarCaracteristicas', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarLocalizacion() {
    try {
      let params = new HttpParams()
        .append('iditem', this.item.iditem)
        .append('idestado', '18')
        .append('idlocalidad', this.item.localizacion.idlocalidad)
        .append('idmunicipio', this.item.localizacion.idmunicipio)
        .append('indicaciones', this.item.localizacion.indicaciones)
        .append('num_exterior', this.item.localizacion.num_exterior)
        .append('orientacion', this.item.localizacion.orientacion)
        .append('vialidad_derecha', this.item.localizacion.vialidad_derecha)
        .append('vialidad_izquierda', this.item.localizacion.vialidad_izquierda)
        .append('vialidad_posterior', this.item.localizacion.vialidad_posterior)
        .append('vialidad_principal', this.item.localizacion.vialidad_principal)
        .append('clave_catastral', this.item.localizacion.clave_catastral)
        .append('colonia', this.item.localizacion.colonia)
        .append('orientacion', this.item.localizacion.orientacion);

      return this.http.post<Respuesta>(this.url + 'Items/modificarLocalizacion', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  modificarCoordenadas(iditem: string, lat: string, lon: string) {
    try {
      let params = new HttpParams()
        .append('iditem', iditem)
        .append('latitud', lat)
        .append('longitud', lon);

      console.log(params);

      return this.http.post<Respuesta>(this.url + 'Items/modificarCoordenadasItem', params, { headers: this.headers }).pipe(map(
        res => {
          console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }


  validarRegistro(iditem: string, supervision: string): Observable<Respuesta> {
    this.cargarUsuario();
    try {

      let params = new HttpParams()
        .append('iditem', iditem)
        .append('idusuario', this.usuario.idusuario);

      let urlAux = '';
      if (supervision === '1') {
        urlAux = this.url + 'Items/validarItem';
      } else {
        urlAux = this.url + 'Items/validarItem2';
      }

      return this.http.post<Respuesta>(urlAux, params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  habilitarRegistro(item: Item, supervision: string): Observable<Respuesta> {
    try {
      let params = new HttpParams()
        .append('iditem', item.iditem)
        .append('observaciones', item.observaciones)
        .append('idusuario', this.usuario.idusuario);

      let urlAux = '';
      if (supervision === '1') {
        urlAux = this.url + 'Items/habilitarItem';
      } else {
        urlAux = this.url + 'Items/habilitarItem2';
      }

      return this.http.post<Respuesta>(urlAux, params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  cerrarCaptura(iditem: string, supervision: string): Observable<Respuesta> {
    this.cargarUsuario();
    try {

      let params = new HttpParams()
        .append('iditem', iditem)
        .append('idusuario', this.usuario.idusuario);

      let urlAux = 'Items/cerrarCaptura';

      return this.http.post<Respuesta>(this.url + urlAux, params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }


  getImagenesJSON(): Observable<Respuesta> {
    try {
      let params = new HttpParams()
        .append('iditem', this.item.iditem);

      return this.http.post<Respuesta>(this.url + 'Items/getImagenesJSON', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  borrarImagen(idimagen: string): Observable<Respuesta> {
    try {
      let params = new HttpParams()
        .append('idimagen', idimagen);

      return this.http.post<Respuesta>(this.url + 'Items/borrarImagen', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }


  guardarImagen(params: Array<string>, file: Array<File>, name: string, iditem: string) {
    // alert(idarchivo);
    let dire = this.url + "Items/guardarImagen";
    return new Promise(function (resolve, reject) {
      let formData: any = new FormData();
      let xhr = new XMLHttpRequest();
      for (let i = 0; i < file.length; i++) {
        formData.append('archivo', file[i], file[i].name);
      }

      formData.append('iditem', iditem);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log(xhr.response);
            resolve(JSON.parse(xhr.response));

          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', dire, true);
      // xhr.setRequestHeader('Authorization', token);
      xhr.send(formData);
    });
  }




  guardarSolicitudDesoficialiar(motivo: string, ids: string): Observable<any> {
    this.cargarUsuario();
    try {
      let params = new HttpParams()
        .append('motivos', motivo)
        .append('idusuario', this.usuario.idusuario)
        .append('ids', ids);

      // console.log(params);

      return this.http.post<Respuesta>(this.url + 'Items/guardarSolicitud', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }


  rechazarSolicitudDesoficialiar(idsolicitud: string, estatus: string, ids: string): Observable<any> {
    this.cargarUsuario();
    try {
      let params = new HttpParams()
        .append('idsolicitud', idsolicitud)
        .append('estatus', estatus)
        .append('ids', ids)
        .append('idusuario', this.usuario.idusuario);

      // console.log(params);

      return this.http.post<Respuesta>(this.url + 'Items/rechazarAutorizarSolicitud', params, { headers: this.headers }).pipe(map(
        res => {
          // console.log(res);
          this.resp = res;
          // console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
