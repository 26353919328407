import { Item } from 'src/app/models/Item';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Tipo } from '../../models/Tipo';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare function initSteps();
declare function init_Files();

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html'
})
export class RegistrarComponent implements OnInit, OnChanges {
  @Input() tipo: Tipo;
  @Input() item: Item;
  @Input() opt: string;



  constructor() {
    this.tipo = new Tipo();
    //this.item = new Item();
  }

  ngOnInit(): void {
    initSteps();
    //init_Files();
  }

  ngOnChanges(){
    console.log(this.item);
    console.log('OPC: ' + this.opt);
  }

}
