export class Localizacion {
  idlocalizacion: string;
  idlocalidad: string;
  idmunicipio: string;
  idestado: string;
  iditem: string;
  clave_catastral: string;
  vialidad_principal: string;
  vialidad_izquierda: string;
  vialidad_derecha: string;
  vialidad_posterior: string;
  colonia: string;
  num_exterior: string;
  orientacion: string;
  indicaciones: string;

  constructor(){
    this.idestado = '18';
    this.idmunicipio = '0';
    this.idlocalidad = '0';
  }


}
