import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identificacion-intangible',
  templateUrl: './identificacion-intangible.component.html'
})
export class IdentificacionIntangibleComponent implements OnInit {


  tipologias: { tipo: string, subtipo: string[] }[] = [
    { tipo: "Tradiciones y expresiones orales", subtipo: ["Adivinanzas", "Canciones", 'Cantos', 'Códigos comunicativo', 'Cuentos', 'Leyendas', 'Nanas', 'Plegarias', 'Poemas épicos', 'Proverbios', 'Sistemas lingüístico', 'Sortilegios'] },
    { tipo: 'Artes del espectáculo', subtipo: ['Carnavales', 'Circo tradicional', 'Teatro', 'Expresiones musicales', 'Juegos autóctonos', 'Danzas tradicionales'] },
    { tipo: 'Usos sociales, rituales y actos festivos', subtipo: ['Sistemas normativos y de justicia', 'Sistemas de organización', 'Ceremonias tradicionales (Nacimiento)', 'Ceremonias tradicionales (Desposorios)', 'Ceremonias tradicionales (Funerales)', 'Ceremonias fúnebre', 'Juramentos de lealtad', 'Festividades (Fiesta popular)', 'Destividades (Fiesta religiosa)', 'Festividades (Fiesta cívica)'] },
    { tipo: 'Rituales', subtipo: ['Ritos de culto', 'Ritos de iniciación', 'Ritos de paso', 'Ritos de decoro', 'Ritos de ceremonia', 'Ritos de magia', 'Ritos de celebración', 'Ritos de liturgia', 'Ritos en sitios sagrados'] },
    { tipo: 'Conocimientos y usos relacionados con la naturaleza y el universo', subtipo: ['Manejo del espacio y entorno geográfico', 'Mitos y concepciones del universo y la naturaleza', 'Conocimientos y técnicas tradicionales agrícolas', 'Conocimientos y técnicas tradicionales forestales', 'Conocimientos y técnicas tradicionales pesqueros', 'Conocimientos y técnicas tradicionales pecuarios', 'Conocimiento y manejo de recursos naturales', 'Conocimientos de medicina y herbolaria tradicional', 'Cocinas tradicionales y saberes culinarios', 'Prácticas domésticas'] },
    { tipo: 'Técnicas artesanales tradicionales', subtipo: ['Manejo y obtención de materias primas', 'Elaboración de utensilios y herramientas'] },
    { tipo: 'Procesos de elaboración de objetos ornamentales y utilitarios', subtipo: ['Accesorios', 'Alimentos', 'Artes decorativas', 'Enseres domésticos', 'Herramientas', 'Indumentaria o textil', 'Instrumentos musicales', 'Joyas', 'Juguetes lúdicos y didácticos', 'Objetos rituales'] }
  ];
  constructor() { }

  ngOnInit(): void {
    console.log(this.tipologias);
  }

}
