<form ngNativeValidate (submit)='guardar()'>
    <div class="form-row mb-4">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Latitud</label>
            <input type="text" class="form-control disabled" id="latitud" name="latitud" [(ngModel)]="item.latitud" placeholder="Latitud" required disabled>
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Londitud</label>
            <input type="text" class="form-control disabled" id="longitud" name="longitud" [(ngModel)]="item.longitud" placeholder="Longitud" required disabled>
        </div>
    </div>
    <div class="form-group mb-4">
        <label for="inputAddress">Nombre</label>
        <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="item.nombre" placeholder="Nombre" required [disabled]=" opt == '1' " disabled>
    </div>
    <div class="form-group mb-4">
        <label for="inputAddress2">Descripcion</label>
        <!-- <input type="text" class="form-control" id="descripcion" name="descripcion" [(ngModel)]="item.descripcion" placeholder="Apartment, studio, or floor"> -->
        <textarea class="form-control" id="descripcion" name="descripcion" [(ngModel)]="item.descripcion" rows="5" [disabled]=" opt == '1' "></textarea>
    </div>
    <!-- <div class="form-row mb-4">
        <div class="form-group col-md-6">
            <label for="inputCity">City</label>
            <input type="text" class="form-control" id="inputCity">
        </div>
        <div class="form-group col-md-4">
            <label for="inputState">State</label>
            <select id="inputState" class="form-control">
              <option selected>Choose...</option>
              <option>...</option>
          </select>
        </div>
        <div class="form-group col-md-2">
            <label for="inputZip">Zip</label>
            <input type="text" class="form-control" id="inputZip">
        </div>
    </div>
    <div class="form-group">
        <div class="form-check pl-0">
            <div class="custom-control custom-checkbox checkbox-info">
                <input type="checkbox" class="custom-control-input" id="gridCheck">
                <label class="custom-control-label" for="gridCheck">Check me out</label>
            </div>
        </div>
    </div> -->
    <button *ngIf=" opt != '1' " type="submit" class="btn btn-primary mt-3">Guardar</button>
</form>
