<h1>Caracteriticas del bien mueble</h1>

<form>
    <div class="form-group">
        <label for="exampleInputEmail1">Estilo</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Autor</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Materiales</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Soporte</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Técnica</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Corriente artística</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Medidas</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Descripción</label>
        <textarea class="form-control" rows="4"></textarea>
    </div>
    <div class="form-group">
        <label for="exampleInputEmail1">Historia de la Pieza</label>
        <textarea class="form-control" rows="4"></textarea>
    </div>


    <button type="button" class="btn btn-primary">Guardar</button>
</form>