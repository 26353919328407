<div class="error404 text-center">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
                <a href="index.html" class="ml-md-5">
                    <img alt="image-404" src="assets/img/LOGO_IPLANAY_90px.png">
                </a>
            </div>
        </div>
    </div>
    <div class="container-fluid error-content">
        <div class="">
            <h1 class="error-number">404</h1>
            <p class="mini-text">Ooops!</p>
            <p class="error-text mb-4 mt-1">Pagina no encontrada!</p>
            <a href="index.html" class="btn btn-primary mt-5">Regresar</a>
        </div>
    </div>
</div>
