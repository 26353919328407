<div id="toggleAccordion">
    <div class="card">
        <div class="card-header" id="...">
            <section class="mb-0 mt-0">
                <div role="menu" class="" data-toggle="collapse" data-target="#generales" aria-expanded="true"
                    aria-controls="generales">
                    Datos Generales
                    <div class="icons"><svg> ... </svg></div>
                </div>
            </section>
        </div>

        <div id="generales" class="collapse" aria-labelledby="..." data-parent="#toggleAccordion">
            <div class="card-body">

                <app-nuevo-item [item]='item'></app-nuevo-item>

            </div>
        </div>
    </div>

    <div class="card" *ngFor="let modulo of tipo.modulos, let i = index">
        <div class="card-header" id="...">
            <section class="mb-0 mt-0">
                <div role="menu" class="" data-toggle="collapse" [attr.data-target]="'#'+modulo.tab"
                    aria-expanded="false" [aria-controls]="modulo.tab">
                    {{modulo.modulo}}
                    <div class="icons"><svg> ... </svg></div>
                </div>
            </section>
        </div>
        <div [id]="modulo.tab" class="collapse" aria-labelledby="..." data-parent="#toggleAccordion">
            <div class="card-body">

                <app-localizacion *ngIf="modulo.tab==='localizacion'" [item]='item' [opt]='opt'
                    [supervisor2]='modulo.supervisor2' [resumen]='modulo.resumen'></app-localizacion>
                <app-imagen *ngIf="modulo.tab==='imagen'" [item]='item' (imagenGuardada)='seGuardoImagen($event)'
                    [supervisor2]='modulo.supervisor2'></app-imagen>
                <app-caractaristicas *ngIf="modulo.tab==='caracteristicas'" [item]='item'
                    [supervisor2]='modulo.supervisor2'></app-caractaristicas>
                <app-estado *ngIf="modulo.tab==='estado'" [item]='item' [supervisor2]='modulo.supervisor2'></app-estado>
                <app-identificacion *ngIf="modulo.tab==='identificacion'" [item]='item'
                    [supervisor2]='modulo.supervisor2'></app-identificacion>
                <app-clasificacion *ngIf="modulo.tab==='clasificacion'" [item]='item'
                    [supervisor2]='modulo.supervisor2'></app-clasificacion>
                <app-descripcion *ngIf="modulo.tab==='descripcion'"></app-descripcion>
                <app-clasificacion-bio *ngIf="modulo.tab==='biocultural'"></app-clasificacion-bio>
                <app-identificacion-mueble *ngIf="modulo.tab==='identificacion_mueble'"></app-identificacion-mueble>
                <app-analisis-mueble *ngIf="modulo.tab==='analisis_mueble'"></app-analisis-mueble>
                <app-caracteristicas-mueble *ngIf="modulo.tab==='caracteristicas_mueble'"></app-caracteristicas-mueble>
                <app-valoracion-mueble *ngIf="modulo.tab==='valoracion_mueble'"></app-valoracion-mueble>
                <app-proteccion-mueble *ngIf="modulo.tab==='proteccion_mueble'"></app-proteccion-mueble>
                <app-origen-intangible *ngIf="modulo.tab==='origen_intangible'"></app-origen-intangible>
                <app-identificacion-intangible
                    *ngIf="modulo.tab==='identificacion_intangible'"></app-identificacion-intangible>
            </div>
        </div>
    </div>


</div>