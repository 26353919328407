import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-origen-intangible',
  templateUrl: './origen-intangible.component.html'
})
export class OrigenIntangibleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
