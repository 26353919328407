import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Usuario } from 'src/app/models/Usuario';
import { Municipio } from '../../models/Municipio';
import { Item } from '../../models/Item';
import { LocalidadClass } from '../../clases/localidad.class';
import { ItemClass } from '../../clases/items.class';
import { Tipo } from 'src/app/models/Tipo';

@Component({
  selector: 'app-localizacion',
  templateUrl: './localizacion.component.html'
})
export class LocalizacionComponent implements OnInit, OnChanges {

  @Input() item: Item;
  @Input() opt: string;
  @Input() supervisor2: string;
  @Input() resumen: string = '0';



  // Clases

  usuario: Usuario;
  municipio: Municipio;
  orientaciones: string[] = ['NORTE', 'SUR', 'ORIENTE', 'PONIENTE'];

  constructor(public objLocalizacion: LocalidadClass, public objItem: ItemClass) {
    this.usuario = new Usuario();
    this.municipio = new Municipio();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));

  }

  ngOnInit(): void {

  }

  ngOnChanges(): any {
    // alert(this.supervisor2);

    let tipoActual: Tipo;
    tipoActual = new Tipo();
    tipoActual = JSON.parse(localStorage.getItem('tipoActual'));
    this.opt = JSON.parse(localStorage.getItem('opt'));
    if (tipoActual.supervision === '2') {
      if (this.supervisor2 === '0') {
        this.opt = '1';
      }
    }

    if (this.item.localizacion.idmunicipio !== '') {
      this.getLocalidades(this.item.localizacion.idmunicipio);
    }
  }

  getLocalidades(event: any): any {
    // console.log(event);
    this.objLocalizacion.getLocalidades(event);
  }


  modificarLocalizacion(): any {
    this.objItem.modificarLocalizacionItem(this.item);
  }

  municipiosParaAlta(): any {
    // console.log(this.usuario.municipios.filter(x => x.alta === '1'));
    return this.usuario.municipios.filter(x => x.alta === '1');
  }

}
