import { Component, OnInit } from '@angular/core';

//declare function init_load();
//declare function init_plugins();
declare function init_custom();
declare function init_dash_1();
//declare function initPerfect(): any;

//declare const $: any;

declare const App: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'iplanaycentros';

  ngOnInit(){

    setTimeout(() => {

    }, 2000);
    //init_plugins();
    App.init();
    init_custom();
    init_dash_1();
    //initPerfect();
      //location.reload();



  }
}
