<h1>Identificación</h1>

<form>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputEmail1">Codigo</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                <small id="emailHelp" class="form-text text-muted">Clasificación o código a 12 dígitos.</small>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Nombre o denominación</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Categoría</label>
                <select name="categoria" class="form-control" (change)="selectCategorias($event.target.value)">
                    <option [value]="i" *ngFor="let cat of categorias; let i=index;">{{cat.categoria}}
                    </option>

                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Subcategoria</label>
                <select name="zonaB" id="" class="form-control" (change)="selectSubcategorias($event.target.value)">
                    <option value="">Seleccione una subcategoria...</option>
                    <option [value]="j" *ngFor="let sub of categoria.subcategorias; let j = index;">{{sub.subcategoria}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Opción</label>
                <select name="zonaB" id="" class="form-control">
                    <option value="">Seleccione una opcion...</option>
                    <option [value]="k" *ngFor="let opt of subcategoria.opciones; let k = index;">
                        {{opt}}</option>
                </select>
            </div>
        </div>
    </div>


    <div class="form-group">
        <label for="exampleInputPassword1">Tipología</label>
        <input type="text" class="form-control" id="exampleInputPassword1">
    </div>
    <div class="form-group">
        <label for="exampleInputPassword1">Temporalidad / Periodo histórico</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Preclásico: 2500 a.C. a 200 d.C.</option>
            <option value="">Clásico: 300 a 800</option>
            <option value="">Posclásico: 900 a 1521</option>
            <option value="">Periodo colonial: 1521-1821</option>
            <option value="">Siglo XIX: 1822-1910</option>
            <option value="">Siglo XX: 1910-2000</option>
            <option value="">Cronología (año de producción o elaboración)</option>
        </select>
    </div>

    <button type="button" class="btn btn-primary">Guardar</button>
</form>