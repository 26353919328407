<h1>Analisis del bien mueble</h1>

<form>

    <div class="form-group">
        <label for="exampleInputPassword1">Estado de conservació</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Bueno: 30% o menos de deterioro</option>
            <option value="">Regular: 40% a 70% de deterior</option>
            <option value="">Malo: Más del 70% de deterioro</option>
        </select>
    </div>
    <div class="form-group">
        <label for="exampleInputPassword1">Régimen de propiedad</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Federal</option>
            <option value="">Estatal</option>
            <option value="">Municipal</option>
            <option value="">Regional</option>
            <option value="">Particular</option>
            <option value="">Corporativo</option>
        </select>
    </div>

    <div class="form-group">
        <label for="exampleInputPassword1">Fuente de Información (APA)</label>
        <textarea class="form-control" rows="3"></textarea>

    </div>



    <button type="button" class="btn btn-primary">Guardar</button>
</form>