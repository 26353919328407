<h1>Protección del mueble</h1>

<form>

    <div class="form-group">
        <label for="exampleInputPassword1">Estado</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Protegido</option>
            <option value="">No Protegido</option>

        </select>
    </div>
    <div class="form-group">
        <label for="exampleInputPassword1">Régimen</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Estatal</option>
            <option value="">Federal</option>
            <option value="">Municipal1</option>
            <option value="">Ejidal</option>
            <option value="">Privado</option>
        </select>
    </div>
    <div class="form-group">
        <label for="exampleInputPassword1">Tipología jurídica</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Bien de interés artístico</option>
            <option value="">Monumento histórico</option>
        </select>
    </div>

    <div class="form-group">
        <label for="exampleInputPassword1">Publicado en:</label>
        <select name="zonaB" id="" class="form-control">
            <option value="">Diario Oficial de la Federación</option>
            <option value="">Catálogo Oficial del INAH</option>
            <option value="">Catálogo Oficial de INBAL</option>
            <option value="">Periódico Oficial del Estado</option>
        </select>
    </div>

    <div class="form-group">
        <label for="exampleInputPassword1">Fecha</label>
        <input type="date" class="form-control" id="exampleInputPassword1">
    </div>

    <div class="form-group">
        <label for="exampleInputPassword1">Numero</label>
        <input type="number" class="form-control" id="exampleInputPassword1">
    </div>

    <div class="form-group">
        <label for="exampleInputPassword1">Pagina</label>
        <input type="number" class="form-control" id="exampleInputPassword1">
    </div>

    <button type="button" class="btn btn-primary">Guardar</button>
</form>