import { Usuario } from './../../models/Usuario';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tipo } from '../../models/Tipo';
import { ItemClass } from '../../clases/items.class';
import { Item } from '../../models/Item';


declare const $: any;

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html'
})
export class MapaComponent implements OnInit {

  title = 'Mapa IPLANAY';
  strTipo: string;
  tipo: Tipo;
  itemN: Item;
  private sub: any;
  modificacion: string;
  vistaMapa: string;
  usuario: Usuario;


  zoom = 15;
  // lat = 21.504163127666864;
  // lng = -104.89461760596936;
  lat = 0;
  lng = 0;

  nlat: number;
  nlng: number;


  constructor(private route: ActivatedRoute, private router: Router, public item: ItemClass) {
    this.tipo = new Tipo();
    this.itemN = new Item();
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.item.items = [];
      this.itemN = new Item();
      this.tipo = JSON.parse(localStorage.getItem('tipoActual'));
      this.itemN.idtipo = this.tipo.idtipo;
      this.itemN.tipo = this.tipo.tipo;
      this.strTipo = params.tipo; // (+) converts string 'id' to a number
      console.log('TIPO: ' + this.tipo);


      // alert(localStorage.getItem('modificarItem'));
      this.modificacion = localStorage.getItem('modificarItem');
      this.vistaMapa = localStorage.getItem('vistaMapa');
      if (this.modificacion === '0') {
        this.item.tipo = this.tipo;

        this.itemN.localizacion.idmunicipio = this.usuario.municipios[0].idmunicipio;
        this.getItems();

        this.getPosition();

      } else {
        this.itemN = JSON.parse(localStorage.getItem('itemDetalle'));
        this.item.items.push(this.itemN);
        this.lat = Number(this.itemN.latitud);
        this.lng = Number(this.itemN.longitud);
        console.error('Modificando Item: ' + this.itemN);
        this.muestraModalPropuedades(this.itemN);
      }
    }
    );

  }

  mostrarPunto(evento) {

    this.nlat = evento.coords.lat;
    this.nlng = evento.coords.lng;
    //this.openModal();
  }

  mostrarCoor(evento, item): void {
    // console.log(evento.coords.lat);
    this.item.items[0].latitud = evento.coords.lat;
    this.item.items[0].longitud = evento.coords.lng;
  }

  actualizaCoordenadas(evento): void {
    this.item;
  }

  nuevoItem(evento): void {
    if (this.vistaMapa === '0') {
      this.itemN.latitud = evento.coords.lat;
      this.itemN.longitud = evento.coords.lng;
      $('#modalNuevo').modal('show');
    }
  }


  guardarItem(event: Item): void {
    this.itemN = event;
    console.log(event);
    console.log(event.iditem);
    $('#modalNuevo').modal('hide');



    localStorage.setItem('itemDetalle', JSON.stringify(this.itemN));
    localStorage.setItem('modificarItem', '1');
    localStorage.setItem('vistaMapa', '0');
    localStorage.setItem('opt', '2');
    this.router.navigate(['/detalle', this.tipo.tipo, '2', '0']);



    // $('#modalPropiedades').modal('show');
  }

  muestraModalPropuedades(item: Item) {
    this.itemN = item;
    $('#modalPropiedades').modal('show');
  }


  detalleItem(item: Item) {

    //1 - Detales
    //2 - Modificar
    //3 - Eliminar


    // console.log('ITEM: ' + item.nombre);
    localStorage.setItem('itemDetalle', JSON.stringify(item));
    localStorage.setItem('modificarItem', '1');
    localStorage.setItem('vistaMapa', '0');
    localStorage.setItem('opt', '1');
    // this.router.navigate(['/mapa', item.tipo]);
    this.router.navigate(['/detalle', item.tipo, '1', '0']);
  }

  getPosition(): Promise<any> {
    //alert('Obtener Posicion Actual');
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(
        resp => {
          console.log(resp);
          this.lat = resp.coords.latitude;
          this.lng = resp.coords.longitude;
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        err => {
          this.lat = 21.504163127666864;
          this.lng = -104.89461760596936;
          reject(err);
        });
    });

  }


  regresar(): void {
    localStorage.setItem('tipoActual', JSON.stringify(this.tipo));
    this.router.navigate(['/listado', this.tipo.tipo]);
  }

  getItems() {
    this.item.getItems();
  }

}
