import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tipo } from '../../models/Tipo';
import { ItemService } from '../../services/item.service';
import { Respuesta } from '../../models/Respuesta';
import { Item } from 'src/app/models/Item';

import Swal from 'sweetalert2';
import { Usuario } from '../../models/Usuario';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-listado-items',
  templateUrl: './listado-items.component.html'
})
export class ListadoItemsComponent implements OnInit {

  @ViewChild('tablaLista') pdfTable: ElementRef;

  private sub: any;
  tipo: Tipo;
  strTipo: string;
  resp: Respuesta;
  item: Item;
  items: Item[] = [];
  itemsDesoficializar: Item[] = [];
  usuario: Usuario;
  supervision_tipo: string;
  txtVPrincipal: string = '';
  estatusC: string = '';

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  cargando: boolean = true;
  desoficializar: boolean = false



  constructor(private route: ActivatedRoute, private _itemService: ItemService, private router: Router) {
    this.tipo = new Tipo();
    this.item = new Item();
    this.resp = new Respuesta();
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));

    this.inicializaOpcionesTable();
  }

  inicializaOpcionesTable() {
    this.dtOptions = {
      //'sPaginationType': 'full_numbers',
      'dom': 'Blfrtip',
      //'retrieve': true,
      //'destroy': true,

      'order': [],
      'responsive': true,
      'language': {
        'search': 'Buscar:',
        'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
        'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
        'lengthMenu': 'Mostrar _MENU_ registros',
        'paginate': {
          'first': 'Primero',
          'last': 'Ultimo',
          'next': 'Siguiente',
          'previous': 'Anterior'
        }
      }
    };
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.tipo = JSON.parse(localStorage.getItem('tipoActual'));
      this.strTipo = params.tipo; // (+) converts string 'id' to a number
      this.supervision_tipo = params.supervision;

      // console.log('TIPO: ' + this.tipo);
      this.getItems();

      // In a real app: dispatch action to load the details here.
    });


  }

  getItems(): void {
    this.cargando = true;
    localStorage.setItem("txtVPrincipal", this.txtVPrincipal);
    localStorage.setItem("estatusC", this.estatusC);
    this._itemService.getItems(this.tipo, this.txtVPrincipal, this.estatusC).subscribe(

      data => {
        this.resp = data;
        this.cargando = false;
        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {
          this.items = this.resp.data;
          //console.log(this.resp.data);
          this.dtTrigger.next();

        }
      },
      err => {
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      //this.dtTrigger.next();
      this.getItems();
    });
  }

  consultaOficializados() {

    this.estatusC = '4';
    this.desoficializar = true
    this.rerender();

  }

  selectItem(index: number) {

    if (this.items[index].seleccionado) {
      this.items[index].seleccionado = false
      this.itemsDesoficializar.splice(this.itemsDesoficializar.indexOf(this.items[index]), 1);
    } else {
      this.items[index].seleccionado = true;
      this.itemsDesoficializar.push(this.items[index]);
    }
    console.log(this.itemsDesoficializar);
  }

  cancelarSolicitud() {
    localStorage.removeItem('listaDesoficializar');
    this.itemsDesoficializar = [];
    this.estatusC = '';
    this.desoficializar = false;
    this.rerender();
  }

  guardaDesoficializacion() {
    localStorage.setItem('listaDesoficializar', JSON.stringify(this.itemsDesoficializar));
    this.router.navigate(['/desoficializar']);
  }

  consultaSolicitudes() {
    //localStorage.setItem('listaDesoficializar', JSON.stringify(this.itemsDesoficializar));
    this.router.navigate(['/solicitudes']);
  }

  solicitarDesoficialarUno(item: Item) {
    Swal.fire({
      title: '¿Solicitar desoficialización?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.itemsDesoficializar.push(item);
        localStorage.setItem('listaDesoficializar', JSON.stringify(this.itemsDesoficializar));
        this.router.navigate(['/desoficializar']);
      }
    })


  }

  deleteItems() {

    this._itemService.deleteItem(this.item.iditem).subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        } else {

          Swal.fire(
            'Eliminar',
            this.resp.mensaje,
            'success'
          );
          this.getItems();
        }

      },
      err => {
        Swal.fire(
          'Error' + err.status,
          err.statusText,
          'error'
        );
      }
    );
  }


  verMapa(tipo: Tipo) {
    localStorage.setItem('tipoActual', JSON.stringify(tipo));
    localStorage.setItem('modificarItem', '0');
    localStorage.setItem('vistaMapa', '1');
    localStorage.setItem('opt', "0");
    this.router.navigate(['/mapa', tipo.tipo]);
  }

  nuevoItem(tipo: Tipo) {
    localStorage.setItem('tipoActual', JSON.stringify(tipo));
    localStorage.setItem('modificarItem', '0');
    localStorage.setItem('vistaMapa', '0');
    localStorage.setItem('opt', "0");
    this.router.navigate(['/mapa', tipo.tipo]);
  }

  detalleItem(item: Item, opt: string) {

    //1 - Detales
    //2 - Modificar
    //3 - Eliminar

    // console.log('ITEM: ' + item.nombre);
    localStorage.setItem('itemDetalle', JSON.stringify(item));
    // console.log(item);
    localStorage.setItem('modificarItem', '1');
    localStorage.setItem('vistaMapa', '0');
    localStorage.setItem('opt', opt);

    // this.router.navigate(['/mapa', item.tipo]);

    if (opt === '2') {
      this.router.navigate(['/editar', item.tipo, opt, item.validado]);
    } else {
      this.router.navigate(['/detalle', item.tipo, opt, item.validado]);
    }

  }


  eliminarItem(item: Item) {
    Swal.fire({
      title: '¿Eliminar Entrada?',
      text: "¿Estas Seguro?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Eliminado',
        //   'Temporalmente desactivada esta opción',
        //   'success'
        // );
        this.item = item;
        this.deleteItems();
      }
    });
  }


  public descargaPDF() {
    const doc = new jsPDF();

    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const pdfTable = this.pdfTable.nativeElement;


    doc.html(document.body, {
      callback: function (doc) {
        doc.save('tableToPdf.pdf');
      },
      x: 15,
      y: 15
    });

    //doc.save('tableToPdf.pdf');
  }

  public descargaPDF2() {
    var data = document.getElementById('tablaLista');

    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'letter'); // A4 size page of PDF
      var position = 10;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, 'Reporte PDF', 'FAST');
      pdf.addPage();
      pdf.save('MYPdf.pdf'); // Generated PDF
    });
  }

}
