import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../../models/Usuario';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  usuario: Usuario;

  constructor(private router: Router) {
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {

  }


  cerrarSesion(){
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
