import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Caracteristica } from '../../models/Caracteristica';
import { Item } from '../../models/Item';
import { ItemClass } from '../../clases/items.class';
import { Tipo } from 'src/app/models/Tipo';

@Component({
  selector: 'app-caractaristicas',
  templateUrl: './caractaristicas.component.html'
})
export class CaractaristicasComponent implements OnInit, OnChanges {

  @Input() item: Item;
  @Input() opt: string;
  @Input() supervisor2: string;

  caracteristica: Caracteristica;
  des: string;
  regimenes: string[] = ['COMUNAL', 'EJIDAL', 'ESTATAL', 'FEDERAL', 'MUNICIPAL', 'PRIVADO', 'OTRO'];
  habitadas: string[] = ['NO', 'PARCIALMENTE DESHABITADA', 'PARCIALMENTE HABITADA', 'SI'];
  niveles: string[] = ['1', '2', '3', '4 O MAS', 'N/D'];
  usos_original: string[] = ['COMERCIO', 'EQUIPAMIENTO', 'HABITACIÓN', 'INDUSTRIAL', 'INSITUCIONAL', 'SERVICIOS', 'OTRO', 'N/D'];
  usos_actal: string[] = ['COMERCIO/SERVICIOS', 'COMERCIO', 'EQUIPAMIENTO', 'HABITACIÓN/COMERCIO', 'HABITACION/SERVICIO', 'HABITACIONAL', 'INDUSTRIAL', 'INSTITUCIONAL', 'SERVICIOS', 'SIN USO', 'OTROS', 'N/D'];

  constructor(public objItem: ItemClass) {
    this.caracteristica = new Caracteristica();
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    let tipoActual: Tipo;
    tipoActual = new Tipo();
    tipoActual = JSON.parse(localStorage.getItem('tipoActual'));
    this.opt = JSON.parse(localStorage.getItem('opt'));
    if (tipoActual.supervision === '2') {
      if (this.supervisor2 === '0') {
        this.opt = '1';
      }
    }
  }


  modificarCaracteristicas(): void {
    this.objItem.modificarCaracteristicasItem(this.item);
  }

}
