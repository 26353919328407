import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../models/Item';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ItemService } from '../../services/item.service';
import { Respuesta } from '../../models/Respuesta';

@Component({
  selector: 'app-guarda-desofizializar',
  templateUrl: './guarda-desofizializar.component.html'
})
export class GuardaDesofizializarComponent implements OnInit {
  @Input() listaDesoficializar: Item[];

  motivo: string = '';
  ids: string = '';
  resp: Respuesta;
  constructor(private router: Router, private _itemService: ItemService) {
    this.resp = new Respuesta();
  }

  ngOnInit(): void {
  }




  guardaSolicitud() {
    //console.log(this.motivo);
    if (this.motivo != "") {
      if (this.listaDesoficializar.length > 0) {
        this.listaDesoficializar.forEach(item => {
          this.ids += item.iditem + ',';
        });
        this._itemService.guardarSolicitudDesoficialiar(this.motivo, this.ids).subscribe(
          resp => {
            this.resp = resp;
            if (this.resp.error) {
              Swal.fire({
                icon: 'error',
                title: 'Guardar Solicitud',
                text: this.resp.mensaje
              });
            } else {
              Swal.fire({
                icon: 'success',
                title: 'Guardar Solicitud',
                text: this.resp.mensaje
              });
            }
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Elementos',
          text: 'La lista no puede estar vacia'

        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Motivo',
        text: 'El campo motivo no puede quedar en vacio'

      });
    }


    console.log(this.ids);

  }



  cancelarDesoficializacion() {
    Swal.fire({
      title: 'Cancelar desoficialización?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        localStorage.removeItem('listaDesoficializar');
        this.router.navigate(['/']);
      }
    })

  }


  eliminaElemento(item: Item) {
    Swal.fire({
      title: 'Eliminar elemento?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.listaDesoficializar.splice(this.listaDesoficializar.indexOf(item), 1);
        Swal.fire('Eliminado!', '', 'success')
      }
    })

  }

}
