<form ngNativeValidate (submit)='modificarEstadoConservacion()'>

    <div class="form-row mb-4">
        <div class="form-group col-md-6">
            <label for="inputState">Estado Actual de Conservación</label>
            <select id="inputState" class="form-control" name='estado' #estado [(ngModel)]="item.conservacion.estado" [disabled]=" opt == '1' ">
              <option *ngFor="let estado of estados"  [value]='estado'>{{estado}}</option>
            </select>
        </div>
        <div class="form-group col-md-6">
            <label for="inputState">Alteraciones</label>
            <select id="inputState" class="form-control" name='alteraciones' #alteraciones [(ngModel)]="item.conservacion.alteraciones" [disabled]=" opt == '1' ">
              <option *ngFor="let alteracion of alteracioness"  [value]='alteracion'>{{alteracion}}</option>
            </select>
        </div>
    </div>

    <button *ngIf=" opt != '1' " type="submit" class="btn btn-primary mt-3">Guardar</button>
</form>