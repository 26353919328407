import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';

import Swal from 'sweetalert2';
import { Respuesta } from '../../models/Respuesta';
import { ItemService } from '../../services/item.service';
import { Item } from '../../models/Item';
import { Tipo } from 'src/app/models/Tipo';

declare function init_Files();

@Component({
  selector: 'app-imagen',
  templateUrl: './imagen.component.html'
})
export class ImagenComponent implements OnInit, OnChanges {

  @Input() item: Item;
  @Input() opt: string;
  @Output() imagenGuardada = new EventEmitter<string>();
  @Input() supervisor2: string;

  resp: Respuesta;

  imagenCargar: Array<File>;

  constructor(private _itemService: ItemService) {
    this.resp = new Respuesta();
  }

  ngOnInit(): void {

  }

  ngOnChanges(): void {

    let tipoActual: Tipo;
    tipoActual = new Tipo();
    tipoActual = JSON.parse(localStorage.getItem('tipoActual'));
    this.opt = JSON.parse(localStorage.getItem('opt'));
    if (tipoActual.supervision === '2') {
      if (this.supervisor2 === '0') {
        this.opt = '1';
      }
    }


  }

  ngAfterViewInit(): void {
    //alert(this.opt);
    if (this.opt !== '1') {
      init_Files();
    }
  }

  notificarGuardarImagen(): void {
    // alert(this.item.imagenes[0].idimagen);
    this.imagenGuardada.emit(this.item.imagenes[0].idimagen);
  }


  cargarImagenes(archivo: any): void {
    this.imagenCargar = <Array<File>>archivo.target.files;
    // console.log(this.imagenCargar);
  }

  guardarImagen(): void {
    // si muestra archivo es verdadero, entonces se esta subiendo otro archivo: MODIFICACION
    // this.guardando = true;
    // this.oficioService.modificacion = this.muestraArchivo;
    this._itemService.guardarImagen([], this.imagenCargar, 'imagen', this.item.iditem).then((result: any) => {
      this.resp = result;
      if (this.resp.error) {
        Swal.fire(
          'Error',
          this.resp.mensaje,
          'error'
        );
      } else {
        Swal.fire(
          'Imagen',
          this.resp.mensaje,
          'success'
        );
        this.getImagenes();
      }


      // this.guardando = false;

    });
  }

  getImagenes(): void {
    this._itemService.item = this.item;
    this._itemService.getImagenesJSON().subscribe((res: Respuesta) => {
      this.resp = res;
      if (!this.resp.error) {
        console.log(this.resp.data);
        this.item.imagenes = this.resp.data;
        this.notificarGuardarImagen();
      }
    });
  }



  eliminarImagen(idimagen: string): void {
    Swal.fire({
      title: '¿Eliminar Imagen?',
      text: '¿Estas Seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Eliminado',
        //   'Temporalmente desactivada esta opción',
        //   'success'
        // );
        this.borrarImagen(idimagen);
      }
    });

  }

  borrarImagen(idimagen: string): void {
    this._itemService.borrarImagen(idimagen).subscribe((res: Respuesta) => {
      this.resp = res;
      if (this.resp.error) {
        Swal.fire(
          'Error',
          this.resp.mensaje,
          'error'
        );
      } else {
        Swal.fire(
          'Eliminada',
          this.resp.mensaje,
          'success'
        );

        this.getImagenes();
      }
    });
  }




}
