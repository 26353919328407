<form ngNativeValidate (submit)='modificarLocalizacion()'>

    <div class="form-row mb-4">
        <!-- <div class="form-group col-md-4">
            <label for="inputCity">Estado</label>
            <select id="inputState" class="form-control">
            <option selected>Choose...</option>
            <option>...</option>
        </select>
    </div> -->
        <div class="form-group col-md-6">
            <label for="inputState">Municipio</label>
            <select id="inputState" class="form-control" name='idmunicipio' #idmunicipio
                [(ngModel)]="item.localizacion.idmunicipio" (ngModelChange)='getLocalidades($event)'
                [disabled]=" opt == '1' " required>
                <option *ngFor="let municipio of municipiosParaAlta()" [value]='municipio.idmunicipio'>
                    {{municipio.municipio}}</option>
            </select>
        </div>
        <div class="form-group col-md-6">
            <label for="inputCity">Localidad</label>
            <select id="inputState" class="form-control" name='idlocalidad' #idlocalidad
                [(ngModel)]="item.localizacion.idlocalidad" [disabled]=" opt == '1' " required>
                <option *ngFor="let localidad of objLocalizacion.localidades" [value]='localidad.idlocalidad'>
                    {{localidad.localidad}}</option>
            </select>
        </div>
    </div>
    <div class="form-row mb-4" *ngIf="resumen==='0'">
        <div class="form-group col-md-4">
            <label for="inputAddress2">Colonia</label>
            <input type="text" class="form-control" id="colonia" name='colonia' #colonia
                [(ngModel)]="item.localizacion.colonia" [disabled]=" opt == '1' " placeholder="Colonia">
        </div>
        <div class="form-group col-md-4">
            <label for="inputAddress2">Clave Catastral</label>
            <input type="text" class="form-control" id="clave_catastral" name='clave_catastral' #clave_catastral
                [(ngModel)]="item.localizacion.clave_catastral" [disabled]=" opt == '1' " placeholder="Clave Catastral">
        </div>
        <div class="form-group col-md-4">
            <label for="inputAddress2">Vialidad Principal</label>
            <input type="text" class="form-control" id="vialidad_principal" name='vialidad_principal'
                #vialidad_principal [(ngModel)]="item.localizacion.vialidad_principal" [disabled]=" opt == '1' "
                placeholder="Vialidad Principal" required>
        </div>
    </div>
    <div class="form-row mb-4" *ngIf="resumen==='0'">
        <div class="form-group col-md-4">
            <label for="inputAddress2">Vialidad Derecha</label>
            <input type="text" class="form-control" id="vialidad_derecha" name='vialidad_derecha' #vialidad_derecha
                [(ngModel)]="item.localizacion.vialidad_derecha" [disabled]=" opt == '1' "
                placeholder="Vialidad Derecha">
        </div>
        <div class="form-group col-md-4">
            <label for="inputAddress2">Vialidad Izquierda</label>
            <input type="text" class="form-control" id="vialidad_izquierda" name='vialidad_izquierda'
                #vialidad_izquierda [(ngModel)]="item.localizacion.vialidad_izquierda" [disabled]=" opt == '1' "
                placeholder="Vialidad Izquierda">
        </div>
        <div class="form-group col-md-4">
            <label for="inputAddress2">Vialidad Posterior</label>
            <input type="text" class="form-control" id="vialidad_posterior" name='vialidad_posterior'
                #vialidad_posterior [(ngModel)]="item.localizacion.vialidad_posterior" [disabled]=" opt == '1' "
                placeholder="Vialidad Posterior">
        </div>
    </div>

    <div class="form-row mb-4" *ngIf="resumen==='0'">
        <div class="form-group col-md-6">
            <label for="inputAddress2">Número</label>
            <input type="text" class="form-control" id="num_exterior" name='num_exterior' #num_exterior
                [(ngModel)]="item.localizacion.num_exterior" placeholder="Numero" [disabled]=" opt == '1' " required>
        </div>
        <div class="form-group col-md-6">
            <label for="inputCity">Orientación</label>
            <select id="inputState" class="form-control" name='orientacion' #orientacion
                [(ngModel)]="item.localizacion.orientacion" [disabled]=" opt == '1' ">
                <option *ngFor="let orientacion of orientaciones" [value]='orientacion'>{{orientacion}}</option>
            </select>

        </div>
    </div>
    <div class="form-row mb-4" *ngIf="resumen==='0'">
        <div class="form-group col-md-12">
            <label for="inputState">Indicaciones</label>
            <textarea class="form-control" name="indicaciones" id="" rows="3"
                [(ngModel)]="item.localizacion.indicaciones" [disabled]=" opt == '1' "></textarea>
        </div>
    </div>

    <button *ngIf="opt !='1'" type="submit" class="btn btn-primary mt-3">Guardar</button>
</form>