<form ngNativeValidate (submit)='modificarIdentificacion()'>

    <div class="form-row mb-4">
        <div class="form-group col-md-6">
            <label for="inputState">Corriente Estilística</label>
            <select id="inputState" class="form-control" name='corriente_artistica' #corriente_artistica [(ngModel)]="item.identificacion.corriente_artistica" [disabled]=" opt == '1' ">
              <option *ngFor="let corriente of corrientes"  [value]='corriente'>{{corriente}}</option>
            </select>
        </div>
        <div class="form-group col-md-6">
            <label for="inputState">Datación</label>
            <select id="inputState" class="form-control" name='datacion' #datacion [(ngModel)]="item.identificacion.datacion" [disabled]=" opt == '1' ">
              <option *ngFor="let datac of dataciones"  [value]='datac'>{{datac}}</option>
            </select>
        </div>
    </div>

    <button *ngIf="opt != '1'" type=" submit " class="btn btn-primary mt-3 ">Guardar</button>
</form>