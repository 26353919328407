


export class Caracteristica {
    idcaracteristica: string;
    iditem: string;
    regimen: string;
    habitada: string;
    niveles: string;
    uso_original: string;
    uso_actual: string;
    descripcion: string;
}
