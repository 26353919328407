<!-- <app-loader></app-loader> -->
<app-navbar></app-navbar>

<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container animate__fadeIn" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>

    <!-- BEGIN SIDEBAR -->
    <div class="sidebar-wrapper sidebar-theme">

        <nav id="sidebar">
            <app-sidebar></app-sidebar>

        </nav>

    </div>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT PART  -->
    <div id="content" class="main-contentt">



        <!-- <app-home></app-home> -->
        <router-outlet></router-outlet>

        <app-footer></app-footer>
    </div>
    <!--  END CONTENT PART  -->

</div>
<!-- END MAIN CONTAINER -->
