<div class="education layout-spacing ">
    <div class="widget-content widget-content-area">
        <h3>Solicitudes para desoficialización</h3>


        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Solicitud</th>
                    <th>Opciones</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let solicitud of solicitudes">
                    <td>
                        <div>
                            <p><strong>Fecha de Solicitud: </strong>{{solicitud.fecha_solicitud}}</p>
                            <p><strong>Solicitante: </strong>{{solicitud.nombre_solicita}}</p>
                            <p><Strong>Motivos: </Strong>{{solicitud.motivos}}</p>


                            <h4>Elementos seleccionados</h4>

                            <p>
                                <button class="btn btn-primary" type="button" data-toggle="collapse"
                                    [attr.data-target]="'#C'+solicitud.iddesoficializar" aria-expanded="false">Ver
                                    inmuebles</button>
                            </p>

                            <div class="collapse multi-collapse" [id]="'C'+solicitud.iddesoficializar">
                                <div class="card card-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Imagen</th>
                                                    <th>ID</th>
                                                    <th>Nombre</th>
                                                    <th>Descripcion</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of solicitud.items; let i = index;">



                                                    <td>
                                                        <!-- <img *ngIf="item.imagenes[0].idimagen !== null" [src]="'https://asistentedesalud.nayarit.gob.mx/IPLANAY_INMUEBLES/restful/index.php/Items/getImagenItem/' + item.imagenes[0].idimagen" alt="Imagen no Disponible" height="100"> -->
                                                        <img *ngIf="item.imagenes[0].idimagen !== null"
                                                            [src]="'https://inmuebles.iplanay.gob.mx/restful/index.php/Items/getImagenItem/' + item.imagenes[0].idimagen"
                                                            alt="Imagen no Disponible" height="100">
                                                        <img *ngIf="item.imagenes[0].idimagen === null"
                                                            src="assets/img/SIN-IMAGEN.jpeg" alt="Imagen no Disponible"
                                                            height="100">
                                                    </td>
                                                    <td>{{item.iditem}}</td>
                                                    <td>{{item.nombre}}</td>
                                                    <td>{{item.descripcion}}</td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </td>
                    <td>
                        <div class="text-center">
                            <button class="btn btn-outline-primary"
                                (click)="rechazarAutorizarSolicitud(solicitud.iddesoficializar, '2', solicitud.ids)">Autorizar</button>
                            <button class="btn btn-outline-danger"
                                (click)="rechazarAutorizarSolicitud(solicitud.iddesoficializar, '0', solicitud.ids)">Rechazar</button>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>


</div>