<!-- BEGIN LOADER -->
<div id="load_screen">
    <div class="loader">
        <div class="loader-content text-center">
            <div class="spinner-grow align-self-center">
            </div>
            <br> Cargando...
        </div>
    </div>
</div>
<!--  END LOADER -->