import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clasificacion-bio',
  templateUrl: './clasificacion-bio.component.html'
})
export class ClasificacionBioComponent implements OnInit {

  ambitos: string[] = ['Acantilado', 'Arroyo', 'Bahía', 'Barranco', 'Bosque', 'Campo de cultivo', 'Cañón', 'Cascada', 'Cuenca', 'Cueva', 'Desierto', 'Islas e islote', 'Lagos y lagunas', 'Llanura', 'Lomas, montes y colinas', 'Manantial', 'Mar', 'Marisma', 'Meseta', 'Océano', 'Pantano', 'Piedemonte', 'Planicie', 'Playa', 'Río', 'Selva', 'Sierra', 'Valle', 'Volcán'];
  areas: string[] = ['Norte', 'Centro', 'Sur', 'Costa Sur', 'Sierra'];
  zonas: string[] = ['Marismas Nacionales', 'Sierras de San Juan Vallejo', 'Corredor Jaguar', 'Sierra de Álica', 'Sierra Wixárika-Cañones-Águila Real'];
  protecciones: string[] = ['Designaciones internacionales', 'Protección nacional'];
  descripciones: string[] = ['Unicidad del área', 'Principales servicios ecosistémicos', 'Tipos de ecosistemas', 'Especies representativas'];

  constructor() { }

  ngOnInit(): void {
  }

}
