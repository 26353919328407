<h1>Identificación</h1>

<form>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputEmail1">Denominación</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Otras denominaciones</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Código</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Ámbito temático</label>
                <select name="zonaB" id="" class="form-control">
                    <option value="">Tradiciones y expresiones orales</option>
                    <option value="">Artes del espectáculo</option>
                    <option value="">Usos sociales, rituales y actos festivos</option>
                    <option value="">Conocimientos y usos relacionados con la naturaleza y el universo</option>
                    <option value="">Bienes de carácter documental</option>
                    <option value="">Bienes de carácter científico</option>
                    <option value="">Técnicas artesanales tradicionales</option>
                </select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Tipología/Actividad</label>
                <select name="zonaB" id="" class="form-control">
                    <optgroup *ngFor="let tipologia of tipologias;" label="{{tipologia.tipo}}">
                        <option *ngFor="let sub of tipologia.subtipo;">{{sub}}</option>
                    </optgroup>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Región</label>
                <select name="zonaB" id="" class="form-control">
                    <option value="">Norte</option>
                    <option value="">Centro</option>
                    <option value="">Sur</option>
                    <option value="">Costa Sur</option>
                    <option value="">Sierra</option>
                </select>
            </div>
        </div>
    </div>


    <div class="form-group">
        <label for="exampleInputPassword1">Periodicidad</label>
        <input type="date" class="form-control" id="exampleInputPassword1">
    </div>

    <button type="button" class="btn btn-primary">Guardar</button>
</form>