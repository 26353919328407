<h1>Origen</h1>

<form>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputEmail1">Origen histórico</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Modelo organizativo</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Espacio</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Procedencia y transmisión del saber</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Bienes relacionados</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
        <div class="col-md-6">
            <label for="exampleInputPassword1">Productos que se obtienen</label>
            <input type="text" class="form-control" id="exampleInputPassword1">
        </div>
    </div>



    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Procedimientos</label>
                <textarea class="form-control" name="" id="" rows="3"></textarea>
            </div>

        </div>
        <div class="col-md-6">
            <div class="form-group">
                <div class="form-group">
                    <label for="exampleInputPassword1">Herramientas</label>
                    <textarea class="form-control" name="" id="" rows="3"></textarea>
                </div>

            </div>
        </div>
    </div>







    <button type="button" class="btn btn-primary">Guardar</button>
</form>