<div class="education layout-spacing ">
    <div class="widget-content widget-content-area">
        <h3>{{ title }}</h3>
        <h4><small>{{tipo.tipo}}</small></h4>
        <div *ngIf="item.item.validado !== '0'">
            <strong>Capturado por: </strong>{{item.item.nombre_usuario}} <br>
            <strong>Primer Validación: </strong>{{item.item.validado1_nombre}}
            <div *ngIf="item.item.validado2 !== '0'"><strong>Segunda Validación: </strong>{{item.item.validado2_nombre}}</div>
            <hr>
        </div>



        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map: -->


        <!-- <button type="button" class="btn btn-primary mb-2 mr-2" (click)='openModal()'><i class="fa fa-plus-circle" aria-hidden="true"></i> Nuevo</button> -->

        <div *ngIf="tipo.supervision==='1'">
            <button type="button" class="btn btn-primary mb-2 mr-2" (click)='regresar()'><i class="fa fa-arrow-left" aria-hidden="true"></i>Regresar</button>
            <button *ngIf="validado === '0' && tipo.supervision !== '0'" type="button" class="btn btn-success mb-2 mr-2" (click)='validarRegistro()'><i class="fa fa-check-circle-o" aria-hidden="true"></i>Validar Registro</button>
            <button *ngIf="validado !== '0' && tipo.supervision !== '0'" type="button" class="btn btn-warning mb-2 mr-2" (click)='habilitarRegistro()'><i class="fa fa-check-circle-o" aria-hidden="true"></i>Habilitar Registro</button>
        </div>

        <div *ngIf="tipo.supervision==='2'">
            <button type="button" class="btn btn-primary mb-2 mr-2" (click)='regresar()'><i class="fa fa-arrow-left" aria-hidden="true"></i>Regresar</button>
            <button *ngIf="item.item.validado2 === '0' && tipo.supervision !== '0'" type="button" class="btn btn-success mb-2 mr-2" (click)='validarRegistro()'><i class="fa fa-check-circle-o" aria-hidden="true"></i>Validar Registro</button>
            <button *ngIf="item.item.validado2 !== '0' && tipo.supervision !== '0'" type="button" class="btn btn-warning mb-2 mr-2" (click)='habilitarRegistro()'><i class="fa fa-check-circle-o" aria-hidden="true"></i>Habilitar Registro</button>
        </div>

        <div class="row">
            <div class="col-md-4">
                <agm-map (mapClick)="nuevoItem($event)" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]='true' (dragEnd)='mostrarCoor($event)' *ngIf="modificacion === '0'">
                        <agm-info-window [disableAutoPan]="true">
                            Mi ubicación
                        </agm-info-window>
                    </agm-marker>

                    <agm-marker [latitude]="ite.latitud" [longitude]="ite.longitud" [markerDraggable]='opt !== "1"' (dragEnd)='mostrarCoor($event, ite)' *ngFor="let ite of item.items">
                        <agm-info-window [disableAutoPan]="true">
                            <strong>{{ite.nombre}}</strong><br>
                            <strong>Descripción: </strong>{{ite.descripcion}}<br>
                            <strong>Latitud: </strong>{{ite.latitud}}<br>
                            <strong>Longitud: </strong>{{ite.longitud}}<br>
                            <hr>
                            <!-- <button type="button" class="btn btn-sm btn-outline-info" (click)='muestraModalPropuedades(ite)'>Detalles</button> -->
                        </agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
            <div class="col-md-4 layout-spacing">

                <div class="statbox widget box box-shadow">
                    <h3>Datos Generales</h3>
                    <div [routerLinkActive]="['show', 'active']" class="tab-pane fade show active" [id]="'informacion'" role="tabpanel" [attr.aria-labelledby]="'informacion-tab'">
                        <app-nuevo-item [item]='item.item'></app-nuevo-item>
                    </div>
                </div>

            </div>
            <div class="col-md-4 layout-spacing">

                <div class="statbox widget box box-shadow">
                    <h3>Imagenes</h3>
                    <app-imagen [item]='item.item'></app-imagen>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="statbox widget box box-shadow">
                    <h3>Localizacion</h3>
                    <app-localizacion [item]='item.item' [opt]='opt'></app-localizacion>
                </div>
            </div>

            <div class="col-md-6">
                <div class="statbox widget box box-shadow">
                    <h3>Caracteristicas</h3>
                    <app-caractaristicas [item]='item.item'></app-caractaristicas>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="statbox widget box box-shadow">
                    <h3>Clasificación</h3>
                    <app-clasificacion [item]='item.item'></app-clasificacion>
                </div>
            </div>

            <div class="col-md-6">
                <div class="statbox widget box box-shadow">
                    <h3>Identificación</h3>
                    <app-identificacion [item]='item.item'></app-identificacion>
                </div>
            </div>


        </div>
    </div>
</div>
<!-- <app-localizacion *ngIf="modulo.tab==='localizacion'" [item]='item' [opt]='opt'></app-localizacion>
<app-imagen *ngIf="modulo.tab==='imagen'" [item]='item'></app-imagen>
<app-caractaristicas *ngIf="modulo.tab==='caracteristicas'" [item]='item'></app-caractaristicas>
<app-estado *ngIf="modulo.tab==='estado'" [item]='item'></app-estado>
<app-identificacion *ngIf="modulo.tab==='identificacion'" [item]='item'></app-identificacion>
<app-clasificacion *ngIf="modulo.tab==='clasificacion'" [item]='item'></app-clasificacion> -->
