import { Modulo } from './Modulo';

export class Tipo {
    idtipo: string;
    tipo: string;
    modulos: Modulo[];
    siglas: string;
    agregar: string;
    modificar: string;
    eliminar: string;
    supervision: string;

}
