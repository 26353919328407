<div class="education layout-spacing ">
    <div class="widget-content widget-content-area">
        <h3>{{ title }}</h3>
        <h4><small>{{tipo.tipo}} {{validado}}</small></h4>



        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map: -->


        <!-- <button type="button" class="btn btn-primary mb-2 mr-2" (click)='openModal()'><i class="fa fa-plus-circle" aria-hidden="true"></i> Nuevo</button> -->

        <button type="button" class="btn btn-primary mb-2 mr-2" (click)='regresar()'><i class="fa fa-arrow-left" aria-hidden="true"></i>Regresar</button>
        <!-- <button *ngIf="validado === '0' && tipo.supervision !== '0'" type="button" class="btn btn-success mb-2 mr-2" (click)='validarRegistro()'><i class="fa fa-check-circle-o" aria-hidden="true"></i>Validar Registro</button>
        <button *ngIf="validado !== '0' && tipo.supervision !== '0'" type="button" class="btn btn-warning mb-2 mr-2" (click)='habilitarRegistro()'><i class="fa fa-check-circle-o" aria-hidden="true"></i>Habilitar Registro</button> -->

        <div class="row">
            <div class="col-md-4">
                <agm-map (mapClick)="nuevoItem($event)" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]='true' (dragEnd)='mostrarCoor($event)' *ngIf="modificacion === '0'">
                        <agm-info-window [disableAutoPan]="true">
                            Mi ubicación
                        </agm-info-window>
                    </agm-marker>

                    <agm-marker [latitude]="ite.latitud" [longitude]="ite.longitud" [markerDraggable]='opt !== "1"' (dragEnd)='mostrarCoor($event, ite)' *ngFor="let ite of item.items">
                        <agm-info-window [disableAutoPan]="true">
                            <strong>{{ite.nombre}}</strong><br>
                            <strong>Descripción: </strong>{{ite.descripcion}}<br>
                            <strong>Latitud: </strong>{{ite.latitud}}<br>
                            <strong>Longitud: </strong>{{ite.longitud}}<br>
                            <hr>
                            <!-- <button type="button" class="btn btn-sm btn-outline-info" (click)='muestraModalPropuedades(ite)'>Detalles</button> -->
                        </agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
            <div class="col-md-8">
                <app-registrar [tipo]='tipo' [item]='item.item'></app-registrar>
            </div>
        </div>
    </div>
</div>



<!-- Modal Propiedades -->
<!-- <div id="modalPropiedades" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">Propiedades {{tipo.tipo}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
            </div>
            <div class="modal-body" data-spy="scroll" data-target="#navSection" data-offset="100">
                <app-registrar [tipo]='tipo' [item]='item.item'></app-registrar>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i>Cancelar</button>

            </div>
        </div>
    </div>
</div> -->

<!-- Modal Nuevo Item -->
<!-- <div id="modalNuevo" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">Nuevo {{tipo.tipo}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
            </div>
            <div class="modal-body" data-spy="scroll" data-target="#navSection" data-offset="100">
                <app-nuevo-item [item]='item.item' (itemGuardado)='guardarItem($event)'></app-nuevo-item>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i> Cancelar</button>

            </div>
        </div>
    </div>
</div> -->
