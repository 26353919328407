<h1>Clasificacion</h1>

<form>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputEmail1">Denominación</label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                    else.</small>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Categoria</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Zona Biocultural</label>
                <select name="zonaB" id="" class="form-control">
                    <option [value]="zona" *ngFor="let zona of zonas;">{{zona}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Area</label>
                <select name="zonaB" id="" class="form-control">
                    <option [value]="area" *ngFor="let area of areas;">{{area}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Ambito</label>
                <select name="zonaB" id="" class="form-control">
                    <option [value]="ambito" *ngFor="let ambito of ambitos;">{{ambito}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Protección</label>
                <select name="zonaB" id="" class="form-control">
                    <option [value]="proteccion" *ngFor="let proteccion of protecciones;">{{proteccion}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Unicidad del área</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Principales servicios ecosistémicos</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputPassword1">Tipos de ecosistemas</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Especies representativas</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputPassword1">Fuente de Información</label>
                <input type="text" class="form-control" id="exampleInputPassword1">
            </div>
        </div>

    </div>



    <button type="button" class="btn btn-primary">Guardar</button>
</form>