<div id="fuSingleFile" class="col-lg-12 layout-spacing">
    <div class="statbox widget box box-shadow">
        <div class="widget-header">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Imagen Principal</h4>
                </div>
            </div>
        </div>
        <div class="widget-content widget-content-area">
            <div class="custom-file-container" data-upload-id="myFirstImage" *ngIf=" opt != '1' ">
                <label>Subir (Solo un Archivo) <a href="javascript:void(0)" class="custom-file-container__image-clear"
                        title="Clear Image">x</a></label>
                <label class="custom-file-container__custom-file">
                    <input type="file" class="custom-file-container__custom-file__custom-file-input" accept="image/*"
                        (change)="cargarImagenes($event)">

                    <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                    <span class="custom-file-container__custom-file__custom-file-control"></span>
                </label>
                <div class="custom-file-container__image-preview"></div>
                <button type="button" class="btn btn-outline-primary" (click)='guardarImagen()'>Guardar</button>
            </div>
            <hr>

            <div class="card-columns">
                <div class="card" *ngFor="let imagen of item.imagenes">
                    <a [href]="'https://inmuebles.iplanay.gob.mx/restful/index.php/Items/getImagenItem/' + imagen.idimagen"
                        target="_blank">
                        <img [src]="'https://inmuebles.iplanay.gob.mx/restful/index.php/Items/getImagenItem/' + imagen.idimagen"
                            class="card-img-top" alt="...">
                    </a>
                    <div class="card-body">
                        <!-- <p class="card-text">{{imagen.nombre}}</p> -->
                    </div>
                    <div class="card-footer bg-transparent border-danger" *ngIf=" opt != '1' ">
                        <button (click)='eliminarImagen(imagen.idimagen)' class="btn btn-outline-danger"><i
                                class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>