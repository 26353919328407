<form ngNativeValidate (submit)='modificarCaracteristicas()'>

    <div class="form-row mb-4">
        <div class="form-group col-md-4">
            <label for="caracteristica">Régimen/Propiedad</label>
            <select id="caracteristica" class="form-control" name="regimen" [(ngModel)]="item.caracteristica.regimen" required [disabled]=" opt == '1' ">
              <option></option>
              <option *ngFor="let regimen of regimenes" >{{regimen}}</option>
            </select>
        </div>
        <div class="form-group col-md-4">
            <label for="habitada">Habitada</label>
            <select id="habitada" class="form-control" name="habitada" [(ngModel)]="item.caracteristica.habitada" required [disabled]=" opt == '1' ">
              <option>...</option>
              <option *ngFor="let habitada of habitadas" >{{habitada}}</option>
            </select>
        </div>
        <div class="form-group col-md-4">
            <label for="inputCity">Nivel/Edificación</label>
            <select id="inputState" class="form-control" name="niveles" [(ngModel)]="item.caracteristica.niveles" required [disabled]=" opt == '1' ">
              <option></option>
              <option *ngFor="let nivel of niveles" >{{nivel}}</option>
            </select>
        </div>
    </div>
    <div class="form-row mb-4">
        <div class="form-group col-md-6">
            <label for="inputState">Uso Original</label>
            <select id="inputState" class="form-control" name="uso_original" [(ngModel)]="item.caracteristica.uso_original" required [disabled]=" opt == '1' ">
              <option></option>
              <option *ngFor="let usoO of usos_original" >{{usoO}}</option>
            </select>
        </div>
        <div class="form-group col-md-6">
            <label for="inputState">Uso Actual</label>
            <select id="inputState" class="form-control" name="uso_actual" [(ngModel)]="item.caracteristica.uso_actual" required [disabled]=" opt == '1' ">
              <option></option>
              <option *ngFor="let usoA of usos_actal" >{{usoA}}</option>
            </select>
        </div>
    </div>

    <div class="form-row mb-4">
        <div class="form-group col-md-12">
            <label for="inputAddress2">Descripción de la Actividad o Giro</label>
            <!-- <input type="text" class="form-control" id="descripcion" name="descripcion" [(ngModel)]="item.descripcion" placeholder="Apartment, studio, or floor"> -->
            <textarea class="form-control" id="descripcion" name="descripcion" [(ngModel)]="item.caracteristica.descripcion" rows="5" [disabled]=" opt == '1' "></textarea>
        </div>

    </div>


    <button *ngIf=" opt != '1'" type="submit" class="btn btn-primary mt-3">Guardar</button>
</form>