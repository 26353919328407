import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';


import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { ContenedorComponent } from './pages/contenedor/contenedor.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContenedorPrincipalComponent } from './pages/contenedor-principal/contenedor-principal.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { FootComponent } from './components/foot/foot.component';
import { LoginComponent } from './pages/login/login.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { MapaComponent } from './components/mapa/mapa.component';
import { AgmCoreModule } from '@agm/core';
import { RegistrarComponent } from './components/registrar/registrar.component';
import { InmueblesComponent } from './components/inmuebles/inmuebles.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PruebaComponent } from './components/prueba/prueba.component';
import { LocalizacionComponent } from './components/localizacion/localizacion.component';
import { ImagenComponent } from './components/imagen/imagen.component';
import { CaractaristicasComponent } from './components/caractaristicas/caractaristicas.component';
import { EstadoComponent } from './components/estado/estado.component';
import { IdentificacionComponent } from './components/identificacion/identificacion.component';
import { ClasificacionComponent } from './components/clasificacion/clasificacion.component';
import { ListadoItemsComponent } from './components/listado-items/listado-items.component';
import { NuevoItemComponent } from './components/nuevo-item/nuevo-item.component';
import { DetalleItemComponent } from './pages/detalle-item/detalle-item.component';
import { ReportePdfComponent } from './pages/reporte-pdf/reporte-pdf.component';
import { DataTablesModule } from 'angular-datatables';
import { DetalleItem2Component } from './pages/detalle-item2/detalle-item2.component';
import { DetalleItem3Component } from './pages/detalle-item3/detalle-item3.component';
import { DetalleItemListaComponent } from './pages/detalle-item-lista/detalle-item-lista.component';
import { DetalleItemAcordionComponent } from './pages/detalle-item-acordion/detalle-item-acordion.component';
import { RegistrarAcordionComponent } from './components/registrar-acordion/registrar-acordion.component';
import { LoadingComponent } from './components/loading/loading.component';
import { GuardaDesofizializarComponent } from './components/guarda-desofizializar/guarda-desofizializar.component';
import { DesofizializarComponent } from './pages/desofizializar/desofizializar.component';
import { SolicitudesDesoficializarComponent } from './components/solicitudes-desoficializar/solicitudes-desoficializar.component';
import { DescripcionComponent } from './components/descripcion/descripcion.component';


import { AngularEditorModule } from '@kolkov/angular-editor';
import { ClasificacionBioComponent } from './components/biocultural/clasificacion-bio/clasificacion-bio.component';
import { IdentificacionMuebleComponent } from './components/identificacion-mueble/identificacion-mueble.component';
import { AnalisisMuebleComponent } from './components/analisis-mueble/analisis-mueble.component';
import { CaracteristicasMuebleComponent } from './components/caracteristicas-mueble/caracteristicas-mueble.component';
import { ValoracionMuebleComponent } from './components/valoracion-mueble/valoracion-mueble.component';
import { ProteccionMuebleComponent } from './components/proteccion-mueble/proteccion-mueble.component';
import { IdentificacionIntangibleComponent } from './components/identificacion-intangible/identificacion-intangible.component';
import { OrigenIntangibleComponent } from './components/origen-intangible/origen-intangible.component';


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NavbarComponent,
    HomeComponent,
    ContenedorComponent,
    SidebarComponent,
    ContenedorPrincipalComponent,
    PerfilComponent,
    FootComponent,
    LoginComponent,
    Error404Component,
    Error500Component,
    MapaComponent,
    RegistrarComponent,
    InmueblesComponent,
    PruebaComponent,
    LocalizacionComponent,
    ImagenComponent,
    CaractaristicasComponent,
    EstadoComponent,
    IdentificacionComponent,
    ClasificacionComponent,
    ListadoItemsComponent,
    NuevoItemComponent,
    DetalleItemComponent,
    ReportePdfComponent,
    DetalleItem2Component,
    DetalleItem3Component,
    DetalleItemListaComponent,
    DetalleItemAcordionComponent,
    RegistrarAcordionComponent,
    LoadingComponent,
    GuardaDesofizializarComponent,
    DesofizializarComponent,
    SolicitudesDesoficializarComponent,
    DescripcionComponent,
    ClasificacionBioComponent,
    IdentificacionMuebleComponent,
    AnalisisMuebleComponent,
    CaracteristicasMuebleComponent,
    ValoracionMuebleComponent,
    ProteccionMuebleComponent,
    IdentificacionIntangibleComponent,
    OrigenIntangibleComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDajYhVlsVpI7qJiCZa03t67hc5ZwF2uXo'
    }),
    FormsModule,
    HttpClientModule,
    AngularEditorModule,
    DataTablesModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
