<div class="layout-px-spacing">

    <div class="row layout-top-spacing">
        <div class="col-12 center">

            <img src="/assets/img/LOGO_IPLANAY.png" width="100%">
        </div>

    </div>

</div>