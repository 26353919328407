


export class Clasificacion {
  idclasificacion: string;
  iditem: string;
  clasificacion: string;
  caracter: string;
  nivel_intervencion: string;
  estatus_legal: string;
  observaciones: string;
}
