import { Usuario } from './../../models/Usuario';
import { Component, OnInit } from '@angular/core';
import { ignoreElements } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  usuario: Usuario;

  constructor(private router: Router) {
    //alert(localStorage.getItem('usuario'));
    if(localStorage.getItem('usuario') === null){
      router.navigate(['login']);
    }
  }

  ngOnInit(): void {

  }

}
