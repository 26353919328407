import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Item } from '../../models/Item';
import { ItemClass } from '../../clases/items.class';


@Component({
  selector: 'app-estado',
  templateUrl: './estado.component.html'
})
export class EstadoComponent implements OnInit, OnChanges {
  @Input() item: Item;
  @Input() opt: string;
  @Input() supervisor2: string;

  estados: string[] = ['BUENO', 'MALO', 'REGULAR', 'RUINOSO', 'DEMOLIDO', 'N/D'];
  alteracioness: string[] = ['INTEGRA', 'MODIFICADA', 'MUY MODIFICADA', 'REEMPLAZADO', 'N/D'];

  constructor(public objItem: ItemClass) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    this.opt = JSON.parse(localStorage.getItem('opt'));

  }


  modificarEstadoConservacion(): void{
    this.objItem.item = this.item;
    this.objItem.modificarEstadoConservacionItem();
  }

}
