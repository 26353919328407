<div class="education layout-spacing ">
    <div class="widget-content widget-content-area">
        <h3>{{ title }}</h3>
        <h4><small>{{tipo.tipo}}</small></h4>
        {{modificacion}}


        <!-- this creates a google map on the page with the given lat/lng from -->
        <!-- the component as the initial center of the map: -->


        <!-- <button type="button" class="btn btn-primary mb-2 mr-2" (click)='openModal()'><i class="fa fa-plus-circle" aria-hidden="true"></i> Nuevo</button> -->
        <button type="button" class="btn btn-primary mb-2 mr-2" (click)='regresar()'><i class="fa fa-arrow-left"
                aria-hidden="true"></i>Regresar</button>
        <agm-map (mapClick)="nuevoItem($event)" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker [latitude]="lat" [longitude]="lng" *ngIf="modificacion === '0'">
                <agm-info-window [disableAutoPan]="true">
                    Mi ubicación
                </agm-info-window>
            </agm-marker>

            <agm-marker [latitude]="ite.latitud" [longitude]="ite.longitud" [markerDraggable]='modificacion==="1"'
                (dragEnd)=mostrarCoor($event) *ngFor="let ite of item.items">
                <agm-info-window [disableAutoPan]="false" [maxWidth]="320">

                    <button *ngIf='vistaMapa == "1"' type="button" class="btn btn-sm btn-outline-info"
                        (click)='detalleItem(ite)'>Detalles</button>
                    <hr>
                    <strong>{{ite.nombre}}</strong><br>
                    <strong>Estatus: </strong>{{ite.estatus}}<br>
                    <strong>Descripción: </strong>{{ite.descripcion}}<br>
                    <strong>Domicilio: </strong>{{ite.domicilio}}<br>
                    <strong>Latitud: </strong>{{ite.latitud}}<br>
                    <strong>Longitud: </strong>{{ite.longitud}}<br>

                    <hr>

                    <img [src]="'https://inmuebles.iplanay.gob.mx/restful/index.php/Items/getImagenItem/' + ite.imagenes[0].idimagen"
                        alt="..." width="300">


                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>
</div>



<!-- Modal Propiedades -->
<div id="modalPropiedades" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">Propiedades {{tipo.tipo}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <div class="modal-body" data-spy="scroll" data-target="#navSection" data-offset="100">
                <app-registrar [tipo]='tipo' [item]='itemN'></app-registrar>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i>Cancelar</button>
                <!-- <button type="button" class="btn btn-primary">Guardar</button> -->
            </div>
        </div>
    </div>
</div>

<!-- Modal Nuevo Item -->
<div id="modalNuevo" class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myExtraLargeModalLabel">Nuevo {{tipo.tipo}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <div class="modal-body" data-spy="scroll" data-target="#navSection" data-offset="100">
                <app-nuevo-item [item]='itemN' [opt]="'0'" (itemGuardado)='guardarItem($event)'></app-nuevo-item>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i> Cancelar</button>
                <!-- <button type="button" class="btn btn-primary" (click)='guardarItem()'>Guardar___</button> -->
            </div>
        </div>
    </div>
</div>