import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caracteristicas-mueble',
  templateUrl: './caracteristicas-mueble.component.html'
})
export class CaracteristicasMuebleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
