<div class="education layout-spacing ">
    <div class="widget-content widget-content-area">


        <h3>{{ title }}</h3>
        <h4><small>{{tipo.tipo}} ({{itemN.estatus}})</small> </h4>
        <p><small>ID: {{itemN.iditem}}</small></p>


        <div class="row">

            <div class="btn-group btn-group-sm" role="group" aria-label="Opciones">

                <button type="button" class="btn btn-primary" (click)='regresar()'><i class="fa fa-arrow-left"
                        aria-hidden="true"></i>Regresar</button>
                <button type="button" class="btn btn-success" *ngIf="itemN.idestatus === '1' && tipo.agregar === '1'"
                    (click)='cerrarCaptura()'><i class="fa fa-check-circle-o" aria-hidden="true"></i>Cerrar
                    Captura</button>



                <div *ngIf="tipo.supervision==='1'" class="btn-group" role="group">
                    <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                        aria-expanded="false"><i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        Validaciones 1
                    </button>
                    <div class="dropdown-menu">
                        <!-- <button type="button" class="btn btn-primary mb-2 mr-2" (click)='regresar()'><i class="fa fa-arrow-left" aria-hidden="true"></i>Regresar</button> -->
                        <button type="button" class="dropdown-item"
                            *ngIf="itemN.idestatus === '2' && tipo.supervision !== '0'" (click)='validarRegistro()'><i
                                class="fa fa-share" aria-hidden="true"></i>Validar Registro</button>
                        <button type="button" class="dropdown-item"
                            *ngIf="itemN.idestatus === '2' && tipo.supervision !== '0'" (click)='habilitarRegistro()'><i
                                class="fa fa-rotate-left" aria-hidden="true"></i>Rechazar Registro</button>
                    </div>
                </div>


                <div *ngIf="tipo.supervision==='2'" class="btn-group" role="group">
                    <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"
                        aria-expanded="false"><i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        Validaciones 2
                    </button>
                    <div class="dropdown-menu">
                        <!-- <button type="button" class="btn btn-primary mb-2 mr-2" (click)='regresar()'><i class="fa fa-arrow-left" aria-hidden="true"></i>Regresar</button> -->
                        <button type="button" class="dropdown-item"
                            *ngIf="itemN.idestatus === '3' && tipo.supervision !== '0'" (click)='validarRegistro()'><i
                                class="fa fa-share" aria-hidden="true"></i>Oficializar Registro</button>
                        <button type="button" class="dropdown-item"
                            *ngIf="itemN.idestatus === '3' && tipo.supervision !== '0'" (click)='habilitarRegistro()'><i
                                class="fa fa-rotate-left" aria-hidden="true"></i>Rechazar Registro</button>
                    </div>
                </div>

                <a type="button" class="btn btn-danger" target="_blank" [href]="pdf+itemN.iditem"><i
                        class="fa fa-download" aria-hidden="true"></i> Descargar</a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 text-center">
                <img [src]="img" alt="Imagen no Disponible" height="500">
            </div>
            <div class="col-md-6">
                <agm-map (mapClick)="nuevoItem($event)" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]='true'
                        (dragEnd)='mostrarCoor($event)' *ngIf="modificacion === '0'">
                        <agm-info-window [disableAutoPan]="true">
                            Mi ubicación
                        </agm-info-window>
                    </agm-marker>

                    <agm-marker [latitude]="ite.latitud" [longitude]="ite.longitud" [markerDraggable]='opt !== "1"'
                        (dragEnd)='mostrarCoor($event, ite)' *ngFor="let ite of item.items">
                        <agm-info-window [disableAutoPan]="true">
                            <div style="overflow-y: auto;">
                                <strong>{{ite.nombre}}</strong><br>
                                <strong>Descripción: </strong>{{ite.descripcion}}<br>
                                <strong>Latitud: </strong>{{ite.latitud | number}}<br>
                                <strong>Longitud: </strong>{{ite.longitud | number}}<br>
                                <!-- <img *ngIf="item.items[0]" [src]="img + item.items[0].imagenes[0].idimagen" alt="Imagen no Disponible" height="100"> -->
                                <!-- <button type="button" class="btn btn-sm btn-outline-info" (click)='muestraModalPropuedades(ite)'>Detalles</button> -->
                            </div>
                        </agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
        </div>

        <div class="row">

            <div class="col-md-12">
                <hr>
                <app-registrar-acordion [tipo]='tipo' [item]='itemN' (imagenGuardada)='cambioDeImagen($event)'>
                </app-registrar-acordion>
            </div>
        </div>


    </div>