import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proteccion-mueble',
  templateUrl: './proteccion-mueble.component.html'
})
export class ProteccionMuebleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
