import { Tipo } from './Tipo';
import { Municipio } from './Municipio';


export class Usuario {
    idusuario: string;
    nombre: string;
    email: string;
    psw: string;
    iddependencia: string;
    dependencia: string;
    administrador: string;
    supervisor: string;
    cargar: string;
    consultor: string;

    permisos: Tipo[];
    municipios: Municipio[];
}
