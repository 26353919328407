import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css']
})
export class Error404Component implements OnInit {

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'error404');
    this.renderer.addClass(document.body, 'text-center');
  }

  ngOnInit(): void {
  }

}
