import { Item } from './Item';



export class Solicitud {
    iddesoficializar: string;
    solicita: string;
    autorizo: string;
    motivos: string;
    fecha_solicitud: string;
    fecha_respuesta: string;
    estatus: string;
    nombre_solicita: string;
    nombre_autorizo: string;
    ids: string;
    items: Item[] = [];
}
