import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/Usuario';
import { Respuesta } from '../../models/Respuesta';
import { UsuarioService } from '../../services/usuario.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  usuario: Usuario;
  resp: Respuesta;

  constructor(private _usuarioServise: UsuarioService, private router: Router) {
    this.usuario = new Usuario();
    this.resp = new Respuesta();
  }

  ngOnInit(): void {

  }

  login(){
    this._usuarioServise.usuario = this.usuario;
    this._usuarioServise.login().subscribe(
      data => {
        this.resp = data;
        console.log(this.resp);
        if(this.resp.error){
          Swal.fire(
            'Error',
            this.resp.mensaje,
            'error'
          );
        }else{
          this.usuario = this.resp.data[0];
          localStorage.setItem('usuario', JSON.stringify(this.usuario));
          this.router.navigate(['']);
        }
      }
    );
  }

}
