import { Item } from 'src/app/models/Item';
import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { Tipo } from '../../models/Tipo';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Usuario } from '../../models/Usuario';

declare function initSteps(): any;
declare function init_Files(): any;
@Component({
  selector: 'app-registrar-acordion',
  templateUrl: './registrar-acordion.component.html'
})
export class RegistrarAcordionComponent implements OnInit, OnChanges {
  @Input() tipo: Tipo;
  @Input() item: Item;
  @Input() opt: string;
  @Output() imagenGuardada = new EventEmitter<string>();

  usuario: Usuario;



  constructor() {
    this.tipo = new Tipo();
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    // this.item = new Item();
  }

  ngOnInit(): void {
    initSteps();
    // init_Files();
  }

  ngOnChanges(): void {
    // console.log(this.item);
    // console.log('OPT: ' + this.opt);
  }

  seGuardoImagen(event): void{
    // alert('Cambio de Imagen' + event);
    let id: string = event;
    this.imagenGuardada.emit(id);
  }

}
