import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-pdf',
  templateUrl: './reporte-pdf.component.html',
  styleUrls: ['./reporte-pdf.component.css']
})
export class ReportePdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
