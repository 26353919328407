import { Clasificacion } from './Clasificacion';
import { Caracteristica } from './Caracteristica';
import { Localizacion } from './Localizacion';
import { Conservacion } from './Conservacion';
import { Identificacion } from './Identificacion';
import { Imagen } from './Imagen';


export class Item {
  iditem: string;
  idtipo: string;
  tipo: string;
  nombre: string;
  descripcion: string;
  latitud: number;
  longitud: number;
  fecha_creacion: string;
  nombre_usuario: string;
  validado: string;
  validado2: string;
  idestatus: string;
  estatus: string;
  estatus_desc: string;
  observaciones: string;

  clave_catastral: string;
  colonia: string;
  domicilio: string;

  validado1_nombre: string;
  validado2_nombre: string;

  caracteristica: Caracteristica;
  localizacion: Localizacion;
  conservacion: Conservacion;
  identificacion: Identificacion;
  clasificacion: Clasificacion;
  imagenes: Imagen[] = [];

  seleccionado: boolean = false;

  constructor() {
    this.descripcion = 'SIN DESCRIPCION';
    this.caracteristica = new Caracteristica();
    this.localizacion = new Localizacion();
    this.conservacion = new Conservacion();
    this.identificacion = new Identificacion();
    this.clasificacion = new Clasificacion();

    // let img = new Imagen();
    // img.idimagen = '0';
    // img.nombre = 'SIN-IMAGEN.jpeg';
    // this.imagenes.push(img);
  }
}
