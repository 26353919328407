import { AfterViewInit, Component, OnInit } from '@angular/core';


declare function init_custom();
declare function init_dash_1();
declare const $: any;
declare const App: any;


@Component({
  selector: 'app-contenedor',
  templateUrl: './contenedor.component.html'
})
export class ContenedorComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
    App.init();
    init_custom();
    init_dash_1();
  }

  ngAfterViewInit(): void{

  }


}
